import React from "react";
import {
  makeStyles,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {
  DotsHorizontalOutlined,
  ToggleIconButton,
  DestructiveMenuActionConfirmation,
} from "@lumar/shared";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import {
  AccountProjectsContextData,
  ProjectsGridRenderCellParams,
} from "../data/types";
import { useAccountProjectsContext } from "../data/useAccountProjectsContext";
import { useActiveCell } from "./useActiveCell";

const useStyles = makeStyles((theme) => ({
  menu: {
    border: 0,
    width: 224,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
  },
  menuItem: {
    color: theme.palette.grey[700],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "&:focus": {
      backgroundColor: theme.palette.grey[200],
      boxShadow: "unset",
    },
  },
  removeMenuItem: {
    color: theme.palette.red[600],
    "& $icons": {
      color: theme.palette.red[600],
    },
  },
  icons: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(20),
  },
}));

export interface MenuItemProps {
  name: string;
  icon: typeof SvgIcon;
  to?: (params: ProjectsGridRenderCellParams) => string;
  onClick?: (
    params: ProjectsGridRenderCellParams,
    context: AccountProjectsContextData,
  ) => Promise<boolean>;
  removeTitle?: string;
  removeDescription?: string;
  confirmText?: string;
  highlightRed?: boolean;
  disabled?: (params: ProjectsGridRenderCellParams) => boolean;
  hidden?: (params: ProjectsGridRenderCellParams) => boolean;
  "data-testid"?: string;
  "data-pendo"?: string;
}

export function ProjectActionsCell({
  menuItems,
  ...params
}: ProjectsGridRenderCellParams & { menuItems: MenuItemProps[] }): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("projectsList");

  const context = useAccountProjectsContext();

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [confirmingDeletion, setConfirmingDeletion] = React.useState<
    MenuItemProps | undefined
  >(undefined);

  const isMenuOpen = Boolean(anchor);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchor(null);
    setConfirmingDeletion(undefined);
  };

  function getMenuProps(menuItem: MenuItemProps): Record<string, unknown> {
    if (menuItem.to)
      return {
        component: Link,
        to: menuItem.to(params),
      };

    return {
      onClick: () => {
        if (menuItem?.removeTitle) setConfirmingDeletion(menuItem);
        else menuItem.onClick?.(params, context);
      },
    };
  }

  const { ref } = useActiveCell(t("actionsButton"));

  return (
    <>
      <ToggleIconButton
        onClick={handleOpen}
        tabIndex={-1}
        ref={ref}
        size="large"
        data-testid="all-projects-action"
        data-pendo="action-button"
      >
        <DotsHorizontalOutlined />
      </ToggleIconButton>
      <Menu
        classes={{
          paper: classes.menu,
        }}
        open={isMenuOpen && !confirmingDeletion}
        onClose={handleClose}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        data-testid="all-projects-menu"
      >
        {menuItems.map(
          (menuItem, idx) =>
            !menuItem.hidden?.(params) && (
              <MenuItem
                key={idx}
                {...getMenuProps(menuItem)}
                disabled={menuItem.disabled?.(params)}
                className={clsx(classes.menuItem, {
                  [classes.removeMenuItem]: Boolean(menuItem.highlightRed),
                })}
                data-testid={menuItem["data-testid"]}
                data-pendo={menuItem["data-pendo"]}
              >
                <menuItem.icon className={classes.icons} />
                <Typography variant="body2">{menuItem.name}</Typography>
              </MenuItem>
            ),
        )}
      </Menu>
      {confirmingDeletion !== undefined && (
        <DestructiveMenuActionConfirmation
          title={confirmingDeletion.removeTitle ?? ""}
          description={confirmingDeletion.removeDescription}
          confirmText={confirmingDeletion.confirmText ?? t("deletion.confirm")}
          cancelText={t("deletion.cancel")}
          anchorEl={anchor}
          onConfirm={async () => {
            const result = await confirmingDeletion.onClick?.(params, context);
            if (result) handleClose();
          }}
          onCancel={handleClose}
        />
      )}
    </>
  );
}
