import {
  DestructiveMenuActionConfirmation,
  Snackbar,
  ToggleIconButton,
  TrashSolid,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

import { enqueueSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useDeleteCustomReportTemplateMutation } from "../../graphql";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../_common/routing/routes";
import { useSearchParam } from "../../_common/routing/useSearchParam";

interface DeleteCustomReportDialogProps {
  customReportTemplateId: string;
  isPendingReportGeneration: boolean;
  disabled?: boolean;
}

export const DeleteCustomReportDialog = (
  props: DeleteCustomReportDialogProps,
): JSX.Element => {
  const disabled = props.disabled ?? false;
  const { t } = useTranslation("customReports");

  const classes = useStyles();
  const ref = useRef<HTMLButtonElement | null>(null);
  const history = useHistory();
  const segmentId = useSearchParam("segmentId");
  const { accountId, crawlId, projectId } = useParams<{
    accountId: string;
    crawlId: string;
    projectId: string;
  }>();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [deleteCustomReportTemplate] = useDeleteCustomReportTemplateMutation({
    onCompleted: () => {
      const title = props.isPendingReportGeneration
        ? t("customReportTemplateDeletedPreview")
        : t("customReportTemplateDeleted");
      enqueueSnackbar(<Snackbar title={title} variant="success" />);
      closeDialogAndReset();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar title={error.message} variant="error" />);
    },
    refetchQueries: ["CustomReport", "CrawlContextCustomReports"],
  });

  const closeDialogAndReset = (): void => {
    setDeleteOpen(false);
    if (props.isPendingReportGeneration) {
      history.replace(
        Routes.CustomReports.getUrl({
          accountId,
          crawlId,
          projectId,
          segmentId,
        }),
      );
    }
  };

  return (
    <>
      <ToggleIconButton
        active
        disabled={disabled}
        size="large"
        colorVariant="red"
        onClick={() => {
          setDeleteOpen(true);
        }}
        ref={ref}
        className={classes.button}
      >
        <TrashSolid />
      </ToggleIconButton>
      {deleteOpen && (
        <DestructiveMenuActionConfirmation
          title={t("deleteCustomReportTemplateTitle")}
          description={t("deleteCustomReportTemplateDescription")}
          cancelText={t("cancelButton")}
          confirmText={t("confirmButton")}
          onConfirm={async () => {
            await deleteCustomReportTemplate({
              variables: {
                input: {
                  customReportTemplateId: props.customReportTemplateId,
                },
              },
            });
          }}
          onCancel={() => setDeleteOpen(false)}
          anchorEl={ref.current}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
}));
