import { VariableType } from "json-to-graphql-query";
import { FormValues, MutationData } from "../types";
import { isEqual } from "lodash";

export function getGoogleAnalytics4MutationData(
  projectId: string,
  values: FormValues,
  initialValues: FormValues,
): (MutationData | undefined)[] {
  const valuesChanged = !isEqual(
    initialValues.analytics.googleAnalytics4,
    values.analytics.googleAnalytics4,
  );
  if (!valuesChanged) return [];

  const persistedPropertyId =
    initialValues.analytics.googleAnalytics4.propertyId;

  const property = values.analytics.googleAnalytics4;
  const [googleConnectionId, propertyName] = (property.propertyId || "").split(
    ":",
  );

  const propertyChanged =
    persistedPropertyId !== values.analytics.googleAnalytics4.propertyId;

  const input = {
    lastNDays: convertNumber(property.lastNDays) ?? undefined,
    minimumSessions: convertNumber(property.minimumSessions) ?? undefined,
    minimumViews: convertNumber(property.minimumViews) ?? undefined,
    source: property.source,
  };

  function getDelete(): MutationData | undefined {
    const shouldDelete = Boolean(persistedPropertyId) && propertyChanged;
    if (!shouldDelete) return;

    return [
      {
        deleteGA4PropertyInput: "DeleteGoogleAnalytics4ProjectPropertyInput!",
      },
      {
        deleteGoogleAnalytics4ProjectProperty: {
          __args: {
            input: new VariableType("deleteGA4PropertyInput"),
          },
          googleAnalytics4ProjectProperty: {
            id: true,
          },
        },
      },
      { deleteGA4PropertyInput: { projectId } },
    ];
  }

  function getCreate(): MutationData | undefined {
    const shouldCreate = Boolean(property.propertyId) && propertyChanged;
    if (!shouldCreate) return;

    return [
      {
        ga4PropertyInput: "CreateGoogleAnalytics4ProjectPropertyInput!",
      },
      {
        createGoogleAnalytics4ProjectProperty: {
          __args: {
            input: new VariableType("ga4PropertyInput"),
          },
          googleAnalytics4ProjectProperty: {
            id: true,
          },
        },
      },
      {
        ga4PropertyInput: {
          projectId,
          googleConnectionId,
          propertyName,
          ...input,
        },
      },
    ];
  }

  function getUpdate(): MutationData | undefined {
    const shouldUpdate = Boolean(property.propertyId) && !propertyChanged;
    if (!shouldUpdate) return;

    return [
      {
        ga4PropertyInput: "UpdateGoogleAnalytics4ProjectPropertyInput!",
      },
      {
        updateGoogleAnalytics4ProjectProperty: {
          __args: {
            input: new VariableType("ga4PropertyInput"),
          },
          googleAnalytics4ProjectProperty: {
            id: true,
          },
        },
      },
      {
        ga4PropertyInput: {
          projectId,
          ...input,
        },
      },
    ];
  }

  return [getDelete(), getCreate(), getUpdate()];
}

function convertNumber(value: string): number | undefined {
  if (!value) return undefined;
  const convertedValue: number = +value;
  return isNaN(convertedValue) ? undefined : convertedValue;
}
