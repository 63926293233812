import React from "react";
import { ResourceDetailData } from "../../data/types";
import { DiffEditor } from "@monaco-editor/react";
import { makeStyles } from "@material-ui/core";
import {
  Clock,
  Code,
  EmptyState,
  SmileySad,
  Typography,
  useDateFormatter,
  useTranslation,
} from "@lumar/shared";
import { useRollbar } from "@rollbar/react";
import { CustomSkeleton } from "../../../_common/CustomSkeleton";

const useStyles = makeStyles((theme) => ({
  editor: {
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    background: theme.palette.background.paper,
  },
  head: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: 8,
    marginBottom: 13,
  },
  forehead: {
    backround: "#EBEFF3",
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    height: 70,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginBottom: -30,
  },
  heading: {
    display: "flex",
    background: theme.palette.background.paper,
    border: "1px solid",
    borderBottom: "none",
    borderColor: theme.palette.grey[300],
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: 8,
  },
  footer: {
    height: 40,
    background: theme.palette.primary.light,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  container: {
    background: theme.palette.background.paper,
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    borderRadius: 8,
    padding: 16,
    minHeight: 400,
  },
}));

export function StoredHtml({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("resourceDetail");
  const rollbar = useRollbar();
  const formatDate = useDateFormatter();
  const staticBody = data.attachments.find(
    (a) => a.name === "HtmlStoring/static-body.html",
  );
  const renderedBody = data.attachments.find(
    (a) => a.name === "HtmlStoring/rendered-body.html",
  );

  const [staticHtml, setStaticHtml] = React.useState("");
  const [renderedHtml, setRenderedHtml] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errored, setErrored] = React.useState(false);

  React.useEffect(() => {
    if (
      renderedBody &&
      staticBody &&
      !loading &&
      !errored &&
      !staticHtml &&
      !renderedHtml
    ) {
      Promise.all([
        fetch(staticBody.signedLocation).then((r) => r.text()),
        fetch(renderedBody.signedLocation).then((r) => r.text()),
      ])
        .then(([staticBodyText, renderedBodyText]) => {
          setStaticHtml(staticBodyText);
          setRenderedHtml(renderedBodyText);
        })
        .catch(() => {
          setErrored(true);
          if (rollbar.options.enabled) {
            rollbar.error("Static body fetch failed!");
          } else {
            console.error("Static body fetch failed!");
          }
        })
        .finally(() => setLoading(false));
    }
  }, [
    errored,
    loading,
    renderedBody,
    renderedHtml,
    rollbar,
    staticBody,
    staticHtml,
  ]);

  if (!staticBody || !renderedBody) {
    return (
      <div>
        <div className={classes.head}>
          <Typography variant="h2SemiBold" component="div">
            {t("storedHtml.title")}
          </Typography>
        </div>
        <div className={classes.container}>
          <EmptyState
            height={400}
            icon={<Code />}
            title={t("storedHtml.disabledTitle")}
            description={t("storedHtml.disabledDescription")}
          />
        </div>
      </div>
    );
  }

  const expiryTimestamp = new Date(staticBody.expiresAt).getTime();
  const currentTimestamp = Date.now();
  const timeLeft = expiryTimestamp - currentTimestamp;

  if (timeLeft <= 0) {
    return (
      <div>
        <div className={classes.head}>
          <Typography variant="h2SemiBold" component="div">
            {t("storedHtml.title")}
          </Typography>
        </div>
        <div className={classes.container}>
          <EmptyState
            height={400}
            icon={<Clock />}
            title={t("storedHtml.expiredTitle")}
            description={t("storedScreenshot.expiredDescription")}
          />
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <div className={classes.head}>
          <Typography variant="h2SemiBold" component="div">
            {t("storedHtml.title")}
          </Typography>
          <Typography
            variant="caption"
            style={{ marginLeft: "auto" }}
            component="div"
          >
            {t("storedHtml.expiryText")}{" "}
            <Typography component="span" variant="captionBold">
              {formatDate(expiryTimestamp, {
                timeStyle: "short",
                dateStyle: "medium",
              })}
            </Typography>
          </Typography>
        </div>
        <CustomSkeleton
          background="white"
          height={600}
          variant="rect"
          aria-label={t("storedHtml.loadingAriaLabel")}
        />
      </div>
    );
  }

  if (errored) {
    return (
      <div>
        <div className={classes.head}>
          <Typography variant="h2SemiBold" component="div">
            {t("storedHtml.title")}
          </Typography>
          <Typography
            variant="caption"
            style={{ marginLeft: "auto" }}
            component="div"
          >
            {t("storedHtml.expiryText")}{" "}
            <Typography component="span" variant="captionBold">
              {formatDate(expiryTimestamp, {
                timeStyle: "short",
                dateStyle: "medium",
              })}
            </Typography>
          </Typography>
        </div>
        <div className={classes.container}>
          <EmptyState
            height={400}
            icon={<SmileySad color="error" />}
            title={t("storedHtml.fetchErrorTitle")}
            description={t("storedHtml.fetchErrorDescription")}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={classes.head}>
        <Typography variant="h2SemiBold" component="div">
          {t("storedHtml.title")}
        </Typography>
        <Typography
          variant="caption"
          style={{ marginLeft: "auto" }}
          component="div"
        >
          {t("storedHtml.expiryText")}{" "}
          <Typography component="span" variant="captionBold">
            {formatDate(expiryTimestamp, {
              timeStyle: "short",
              dateStyle: "medium",
            })}
          </Typography>
        </Typography>
      </div>
      <div className={classes.forehead}></div>
      <div className={classes.heading}>
        <Typography component="div" variant="caption" style={{ width: "50%" }}>
          {t("storedHtml.staticHtml")}
        </Typography>
        <Typography component="div" variant="caption" style={{ width: "50%" }}>
          {t("storedHtml.renderedHtml")}
        </Typography>
      </div>
      <DiffEditor
        className={classes.editor}
        height={490}
        original={staticHtml}
        modified={renderedHtml}
        options={{
          // FIXME: Disabling for now to keep headings aligned.
          enableSplitViewResizing: false,
          readOnly: true,
        }}
      />
      <div className={classes.footer}></div>
    </div>
  );
}
