import React from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { FastField, FastFieldProps } from "formik";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { updateIfPropsChanged } from "@lumar/shared";

import { SourcesContext } from "../data/useSourcesFormValues";
import { UpgradeMessage } from "../../components/UpgradeMessage";
import { SearchField } from "../../components/SearchField";
import { GoogleAnalytics4Settings } from "../data/types";
import { GoogleAnalytics4List } from "./GoogleAnalytics4List";
import { GoogleAnalytics4Inputs } from "./GoogleAnalytics4Inputs";
import { GoogleAnalytics4SourceType } from "../../../../graphql";
import { GoogleAnalytics4NoAccountOverlay } from "./GoogleAnalyticsNoAccountOverlay";

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  root: {
    marginTop: theme.spacing(3),
  },
  alignRightGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  list: {
    paddingRight: theme.spacing(2.625),
    marginTop: theme.spacing(1.5),
  },
  inputs: {
    marginTop: theme.spacing(1.5),
  },
  fullWidth: {
    width: "100%",
  },
}));

export function GoogleAnalytics4({ dirty }: { dirty: boolean }): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const [search, setSearch] = React.useState("");

  const {
    isPlanLight,
    googleConnections,
    addGoogleConnection,
    googleAnalytics4Properties,
  } = React.useContext(SourcesContext);

  function handleAddAccount(): void {
    if (
      !dirty ||
      // eslint-disable-next-line no-alert
      window.confirm(t("sources.googleSearchConsole.sureToLeave"))
    ) {
      addGoogleConnection();
    }
  }

  if (isPlanLight)
    return (
      <UpgradeMessage
        upgradeType="LightPlusOrCorporate"
        className={classes.indent}
      />
    );

  if (!googleConnections.length && !googleAnalytics4Properties.length) {
    return <GoogleAnalytics4NoAccountOverlay onAddAccont={handleAddAccount} />;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={4}>
        <SearchField
          value={search}
          onChange={setSearch}
          placeholder={t("sources.analytics.googleAnalytics4.findWebsites")}
          className={classes.fullWidth}
          data-testid="crawl-settings-google-analytics-4-search"
        />
      </Grid>
      <Grid item xs={12} md={8} className={classes.alignRightGrid}>
        <Button
          onClick={handleAddAccount}
          variant="outlined"
          size="small"
          startIcon={<ShowChartIcon />}
        >
          {t("sources.analytics.googleAnalytics4.addAccount")}
        </Button>
      </Grid>
      <FastField
        name="analytics.googleAnalytics4"
        search={search}
        shouldUpdate={updateIfPropsChanged("search")}
      >
        {({
          field: { value },
          form: { setFieldValue, setFieldTouched, isSubmitting },
        }: FastFieldProps<GoogleAnalytics4Settings>) => {
          const property = googleAnalytics4Properties.find(
            (x) => x.propertyId === value.propertyId,
          );

          return (
            <>
              <Grid item xs={12} md={8} className={classes.list}>
                <GoogleAnalytics4List
                  propertyId={property?.propertyId}
                  onChange={(property) => {
                    setFieldTouched("analytics.googleAnalytics4.lastNDays");
                    setFieldTouched(
                      "analytics.googleAnalytics4.minimumSessions",
                    );
                    setFieldTouched("analytics.googleAnalytics4.minimumViews");
                    setFieldTouched("analytics.googleAnalytics4.source");
                    if (!property) {
                      setFieldValue(
                        "analytics.googleAnalytics4",
                        {
                          propertyId: property,
                          lastNDays: "",
                          minimumSessions: "",
                          minimumViews: "",
                          source:
                            GoogleAnalytics4SourceType.OrganicSearchLandingPages,
                        },
                        true,
                      );
                    } else if (!value.propertyId) {
                      setFieldValue(
                        "analytics.googleAnalytics4",
                        {
                          propertyId: property,
                          lastNDays: "90",
                          minimumSessions: "0",
                          minimumViews: "1",
                          source:
                            GoogleAnalytics4SourceType.OrganicSearchLandingPages,
                        },
                        true,
                      );
                    } else {
                      setFieldValue(
                        "analytics.googleAnalytics4.propertyId",
                        property,
                        true,
                      );
                    }
                  }}
                  filter={search}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.inputs}>
                <GoogleAnalytics4Inputs />
              </Grid>
            </>
          );
        }}
      </FastField>
    </Grid>
  );
}
