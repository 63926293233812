import { ReportRowsFilter } from "../../_common/connection-filtering/types";

export type TotalSign = "negative" | "neutral" | "positive";
export type TotalWeight = "none" | "low" | "medium" | "high" | "critical";
export enum CustomReportOverviewType {
  Previews = "previews",
  All = "all",
  Errors = "errors",
}

export type CreateCustomReportTemplateFormValues = {
  projectId: string;
  reportTemplateCode: string;
  name: string;
  description?: string;
  orderBy?: Array<{ field: string; direction: string }>;
  totalSign: TotalSign;
  totalWeight: TotalWeight;
  filter: ReportRowsFilter;
  metricsGroupings: Array<{
    metrics: Array<string>;
  }>;
};

export type UpdateCustomReportTemplateFormValues = {
  customReportTemplateId: string;
  name: string;
  description?: string;
  orderBy?: Array<{ field: string; direction: string }>;
  totalSign: TotalSign;
  totalWeight: TotalWeight;
  metricsGroupings: Array<{
    metrics: Array<string>;
  }>;
};

export type CustomReportTemplateDTO = {
  id: string;
  rawID: string;
  name: string;
  description?: string | null;
  totalSign: number;
  totalWeight: number;
  metricsGroupings?: string[][] | null;
  orderBy?: Array<{ field: string; direction: string }> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: any;
};

export type BaseReportTemplateDTO = {
  id: string;
  name: string;
  code: string;
  primaryReportCategoryCode?: string | null;
  reportCategories: Array<{
    code: string;
    name: string;
    parentCode?: string | null;
  }>;
};
