import React from "react";
import {
  GetProjectPreviewQuery,
  SinglePageRequesterRequestStatus,
  useCreateProjectPreviewMutation,
  useGetProjectPreviewQuery,
} from "../../../graphql";
import { isNumber, isString } from "lodash";

interface Props {
  projectId: string;
  url: string;
}

export interface ProjectPreviewResult {
  data?: {
    screenshot?: Blob;
    statusCode?: number;
    redirectUrl?: string;
    staticHTML?: string;
    renderedHTML?: string;
    responseHeaders?: Record<string, string>;
  };
  loading: boolean;
  errorMessage?: string;
}

export function useProjectPreview({
  projectId,
  url,
}: Props): ProjectPreviewResult {
  const [result, setResult] = React.useState<ProjectPreviewResult>({
    loading: true,
  });

  const [createPreview, { data: mutationData }] =
    useCreateProjectPreviewMutation({
      onError: (error) => {
        const errorMessage = error.graphQLErrors[0]?.message ?? error.message;
        setResult({ loading: false, errorMessage });
      },
    });

  React.useEffect(() => {
    createPreview({
      variables: {
        input: {
          projectId,
          url,
          skipContainers: true,
        },
      },
    });
  }, [createPreview, projectId, url]);

  const requestId =
    mutationData?.createSinglePageRequesterRequest?.singlePageRequesterRequest
      .id;

  const { data, startPolling, stopPolling } = useGetProjectPreviewQuery({
    variables: {
      requestId,
    },
    skip: !requestId,
    onError: (error) => {
      const errorMessage = error.graphQLErrors[0]?.message ?? error.message;
      setResult({ loading: false, errorMessage });
    },
  });

  const request = isNonEmpty(data?.node) ? data?.node : undefined;

  React.useEffect(() => {
    if (request?.status === SinglePageRequesterRequestStatus.Failed) {
      setResult({ loading: false });
      return;
    }

    if (request?.status === SinglePageRequesterRequestStatus.Finished) {
      (async () => {
        const screenshotPromise = request.screenshotDownloadUrl
          ? fetchScreenshot(request.screenshotDownloadUrl)
          : undefined;

        const stepPromise = request.stepDownloadUrl
          ? fetchStepData(request.stepDownloadUrl)
          : undefined;

        const staticHTMLPromise = request.staticBodyDownloadUrl
          ? fetchHTMLBodyData(request.staticBodyDownloadUrl)
          : undefined;

        const renderedHTMLPromise = request.renderedBodyDownloadUrl
          ? fetchHTMLBodyData(request.renderedBodyDownloadUrl)
          : undefined;

        const screenshot = await screenshotPromise;
        const step = await stepPromise;
        const staticHTML = await staticHTMLPromise;
        const renderedHTML = await renderedHTMLPromise;

        setResult({
          loading: false,
          data: {
            screenshot,
            statusCode: step?.statusCode,
            redirectUrl: step?.redirectUrl,
            responseHeaders: step?.responseHeaders,
            staticHTML,
            renderedHTML,
          },
        });
      })();

      return;
    }

    startPolling(2000);
    return () => stopPolling();
  }, [request, startPolling, stopPolling]);

  return result;
}

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];

type ExcludeEmpty<T> = T extends AtLeastOne<T> ? T : never;

function isNonEmpty(
  value: GetProjectPreviewQuery["node"],
): value is ExcludeEmpty<GetProjectPreviewQuery["node"]> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Boolean((value as any)?.id);
}

async function fetchScreenshot(url: string): Promise<Blob | undefined> {
  try {
    const response = await fetch(url);
    return await response.blob();
  } catch {}
}

async function fetchStepData(url: string): Promise<
  | {
      statusCode?: number;
      redirectUrl?: string;
      responseHeaders?: Record<string, string>;
    }
  | undefined
> {
  try {
    const response = await fetch(url);
    const body = await response.json();

    return {
      statusCode: isNumber(body?.statusCode) ? body.statusCode : undefined,
      redirectUrl: isString(body?.page?.resolvedRedirect?.resolvedTarget?.url)
        ? body.page.resolvedRedirect.resolvedTarget.url
        : undefined,
      responseHeaders:
        typeof body?.headers === "object"
          ? Object.fromEntries(
              Object.entries(body.headers).map(([key, value]) => [
                key,
                typeof value === "string" ? value : JSON.stringify(value),
              ]),
            )
          : undefined,
    };
  } catch {}
}

async function fetchHTMLBodyData(url: string): Promise<string | undefined> {
  try {
    const response = await fetch(url);
    return await response.text();
  } catch {}
}
