/* eslint-disable react/display-name */
import { useTranslation } from "@lumar/shared";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { ColumnState } from "../../../../_common/data-grid/column-persistance/columnsState";
import { MetricType } from "../../../../graphql";
import { jsTypes } from "../_common/js-types";
import { CardCell } from "./card-cell/CardCell";
import { DefaultColumnState } from "./DefaultReportGridColumns.types";
import { IndexInterpreter } from "./interpreters/IndexInterpreter";
import {
  ReportGridColumnsResult,
  UseReportGridColumnsArgs,
} from "./ReportGridColumns.types";
import { useDefaultReportGridColumns } from "./useDefaultReportGridColumns";
import { ReportEntity, ReportInput } from "../../../Report.types";

export function useReportGridColumns({
  reportInput,
  columnPersistanceKey,
  overwrites,
}: UseReportGridColumnsArgs): ReportGridColumnsResult {
  const { t } = useTranslation("report");

  const {
    cardMetrics,
    foundInSources,
    possibleMetrics,
    storedStates,
    defaultStates,
    isGridView,
    ...defaultReportGridColumns
  } = useDefaultReportGridColumns({
    reportInput,
    columnPersistanceKey,
    overwrites,
  });

  const definitions = useMemo(() => {
    const cardDefaultState = defaultStates.find((x) => x.code === "card");
    return [
      ...(!isGridView
        ? [
            {
              field: "card",
              headerName: t("urlDetails"),
              sortable: false,
              disableReorder: true,
              disableColumnMenu: true,
              width: cardDefaultState?.width,
              minWidth: cardDefaultState?.minWidth,
              renderCell: (params: GridRenderCellParams) => (
                <CardCell
                  {...params}
                  cardMetrics={cardMetrics}
                  foundInSources={foundInSources}
                  isGridView={isGridView}
                  reportTemplateCode={
                    reportInput.reportEntity === ReportEntity.ReportStat
                      ? reportInput.reportTemplateCode
                      : undefined
                  }
                  /**
                   * We need to subtract 152px from the column width to calculate the
                   * inner container width of the card cell.
                   */
                  containerWidth={params.colDef.computedWidth - 152}
                ></CardCell>
              ),
            },
          ]
        : []),
      ...possibleMetrics.map((column) =>
        columnDefinition({
          column,
          isGridView,
          defaultStates,
          storedStates,
          reportInput,
        }),
      ),
    ];
  }, [
    cardMetrics,
    storedStates,
    defaultStates,
    possibleMetrics,
    foundInSources,
    isGridView,
    reportInput,
    t,
  ]);

  return {
    definitions,
    cardMetrics,
    foundInSources,
    storedStates,
    defaultStates,
    isGridView,
    ...defaultReportGridColumns,
  };
}

function columnDefinition(args: {
  column: {
    code: string;
    name: string;
    description: string;
    type: MetricType;
  };
  isGridView: boolean;
  defaultStates: DefaultColumnState[];
  storedStates: ColumnState[];
  reportInput: ReportInput;
}): GridColDef {
  const { column, isGridView, defaultStates, storedStates } = args;
  const defaultState = defaultStates.find((x) => x.code === column.code);
  const storedState = storedStates.find((x) => x.code === column.code);

  return {
    type: jsTypes.get(column.type),
    description: column.description,
    field: column.code,
    headerName: column.name,
    resizable: true,
    hide: storedState?.hide ?? defaultState?.hide ?? true,
    width: storedState?.width ?? defaultState?.width ?? 0,
    minWidth: defaultState?.minWidth,
    sortable: defaultState?.sortable ?? true,
    align: getGridAlingment({ type: column.type, code: column.code }),
    headerAlign: "left",
    renderCell: (props) => (
      <IndexInterpreter
        {...props}
        code={column.code}
        isGridView={isGridView}
        reportTemplateCode={
          args.reportInput.reportEntity === ReportEntity.ReportStat
            ? args.reportInput.reportTemplateCode
            : undefined
        }
        /**
         * We need to subtract 34px from the column width to account
         * for the 17px padding on each side of the column.
         * @author Alex Sánchez
         */
        containerWidth={props.colDef.computedWidth - 34}
      />
    ),
  };
}

function getGridAlingment({
  type,
  code,
}: {
  type?: MetricType;
  code: string;
}): GridAlignment {
  if (code.includes("httpStatusCode")) return "center";
  if (type === MetricType.Integer || type === MetricType.Decimal) {
    return "right";
  }

  return "left";
}
