import {
  UrlServerPagination,
  DocumentNode,
  gql,
  QueryResult,
  useQuery,
} from "@lumar/shared";
import {
  EnumType,
  jsonToGraphQLQuery,
  VariableType,
} from "json-to-graphql-query";
import {
  CrawlOrderField,
  OrderDirection,
  Account,
  ProjectConnectionFilterInput,
  ProjectConnection,
  CrawlTypeMetadata,
  CrawlType,
  ModuleCode,
} from "../../graphql";
import { GridColumns } from "@mui/x-data-grid-pro";
import { AccountProjectsVariant } from "../../_common/routing/routes";

type AccountProjectsQueryResponse = {
  getAccount?: Pick<Account, "projects" | "accountSettings"> & {
    allProjects?: Pick<ProjectConnection, "totalCount">;
  } & Partial<
      Record<
        `projects${ModuleCode | "All"}`,
        Pick<ProjectConnection, "totalCount">
      >
    >;
  getCrawlTypesMetadata: Array<Pick<CrawlTypeMetadata, "code" | "name">>;
};

export function useAccountProjectsQuery({
  accountId,
  filter,
  baseFilter,
  crawlTypeCodes,
  crawlTypeCodesExcluded,
  pagination,
  columns,
  variant,
  projectCountModules,
}: {
  accountId: string;
  filter: ProjectConnectionFilterInput;
  baseFilter: ProjectConnectionFilterInput;
  crawlTypeCodes?: CrawlType[];
  crawlTypeCodesExcluded?: CrawlType[];
  pagination: UrlServerPagination["pageInfo"];
  columns: GridColumns;
  variant: AccountProjectsVariant;
  projectCountModules: (ModuleCode | "All")[];
}): QueryResult<AccountProjectsQueryResponse> {
  const document = generateAccountProjectsQuery(
    columns,
    variant,
    projectCountModules,
  );

  const response = useQuery<AccountProjectsQueryResponse>(document, {
    variables: {
      accountId,
      filter,
      baseFilter,
      crawlTypeCodes,
      crawlTypeCodesExcluded,
      ...pagination,
    },
    fetchPolicy: "no-cache",
  });

  return response;
}

function generateAccountProjectsQuery(
  columns: GridColumns,
  variant: AccountProjectsVariant,
  projectCountModules: (ModuleCode | "All")[],
): DocumentNode {
  function hasColumn(field: string): boolean {
    return Boolean(columns.find((x) => x.field === field));
  }

  const query = {
    query: {
      __name: "AccountProjects",
      __variables: {
        accountId: "ObjectID!",
        baseFilter: "ProjectConnectionFilterInput",
        filter: "ProjectConnectionFilterInput",
        crawlTypeCodes: "[CrawlType!]",
        crawlTypeCodesExcluded: "[CrawlType!]",
        first: "Int",
        last: "Int",
        before: "String",
        after: "String",
        orderBy: "[ProjectOrder!]",
      },
      getAccount: {
        __args: {
          id: new VariableType("accountId"),
        },
        id: true,
        projects: {
          __args: {
            first: new VariableType("first"),
            last: new VariableType("last"),
            after: new VariableType("after"),
            before: new VariableType("before"),
            orderBy: new VariableType("orderBy"),
            filter: new VariableType("filter"),
            crawlTypeCodes: new VariableType("crawlTypeCodes"),
            crawlTypeCodesExcluded: new VariableType("crawlTypeCodesExcluded"),
            includeTestSuites: variant === AccountProjectsVariant.TestSuite,
          },
          totalCount: true,
          pageInfo: {
            hasNextPage: true,
            startCursor: true,
            endCursor: true,
          },
          edges: {
            cursor: true,
            node: {
              id: true,
              isTestSuite: true,
              name: true,
              primaryDomain: true,
              crawlTypes: true,
              lastCrawlStatus: true,
              finishedAt: true,
              crawlsTotalCount: true,
              crawls: {
                __args: {
                  first: 1,
                  orderBy: {
                    direction: new EnumType(OrderDirection.Desc),
                    field: new EnumType(CrawlOrderField.CreatedAt),
                  },
                },
                edges: {
                  node: {
                    id: true,
                    finishedAt: true,
                    ...(variant === AccountProjectsVariant.TestSuite
                      ? {
                          createdAt: true,
                          crawledPerSecond: true,
                          totalSteps: true,
                          crawlStat: {
                            crawlLevels: {
                              stepsProcessed: true,
                            },
                          },
                        }
                      : {}),
                  },
                },
              },
              lastFinishedCrawl: {
                id: true,
              },
              ...(hasColumn("segmentsTotalCount")
                ? {
                    segmentsTotalCount: true,
                  }
                : {}),
              ...(hasColumn("legacyTasksTotalCount")
                ? {
                    legacyTasks: {
                      __args: {
                        filter: { fixedAt: { isNull: true } },
                      },
                      totalCount: true,
                    },
                  }
                : {}),
              ...(hasColumn("crawlUrls")
                ? {
                    allPagesTotal: true,
                    crawlUrlsTotal: true,
                  }
                : {}),
              ...(hasColumn("frequency") || hasColumn("nextCrawl")
                ? {
                    schedule: {
                      id: true,
                      scheduleFrequency: {
                        code: true,
                        name: true,
                      },
                      nextRunTime: true,
                    },
                  }
                : {}),
              ...(hasColumn("crawlsTotalCount")
                ? {
                    crawlsTotalCount: true,
                  }
                : {}),
              ...(hasColumn("createdAt")
                ? {
                    createdAt: true,
                  }
                : {}),
              ...(hasColumn("projectType")
                ? {
                    moduleCode: true,
                  }
                : {}),
            },
          },
        },
        allProjects: {
          __aliasFor: "projects",
          __args: {
            filter: new VariableType("baseFilter"),
            includeTestSuites: variant === AccountProjectsVariant.TestSuite,
          },
          totalCount: true,
        },
        ...Object.fromEntries(
          projectCountModules.map((moduleCode) => [
            `projects${moduleCode}`,
            {
              __aliasFor: "projects",
              __args: {
                filter:
                  moduleCode === "All"
                    ? {}
                    : { moduleCode: { eq: new EnumType(moduleCode) } },
              },
              totalCount: true,
            },
          ]),
        ),
      },
      getCrawlTypesMetadata: {
        id: true,
        code: true,
        name: true,
      },
    },
  };

  return gql`
    ${jsonToGraphQLQuery(query, {
      pretty: true,
    })}
  `;
}
