import { useTranslation } from "@lumar/shared";
import { Tab, Tabs } from "@material-ui/core";
import { useCrawlContextHelpers } from "../../crawl-overview/CrawlContext";
import { CustomReportOverviewType } from "../_common/CustomReportTypes";

type CustomReportsTabsProps = {
  selectedType: string;
  onTypeChange(type: string): void;
};

export function CustomReportsTabs(props: CustomReportsTabsProps): JSX.Element {
  const { t } = useTranslation("crawlOverview");
  const helpers = useCrawlContextHelpers();

  const errorReports = helpers.getCrawlErrorCustomReportsList();
  const allReports = helpers.getCrawlCustomReportsList();
  const allCustomReportTemplates =
    helpers.getProjectCustomReportTemplatesPendingGenerationList();

  return (
    <Tabs
      value={props.selectedType}
      indicatorColor="primary"
      onChange={(_, type) => {
        props.onTypeChange(type);
      }}
    >
      <Tab
        label={
          <TabLabel name="Previews" count={allCustomReportTemplates.length} />
        }
        value={CustomReportOverviewType.Previews}
      />
      <Tab
        label={<TabLabel name="All Custom Reports" count={allReports.length} />}
        value={CustomReportOverviewType.All}
      />
      <Tab
        label={<TabLabel name={t("tabs.errors")} count={errorReports.length} />}
        value={CustomReportOverviewType.Errors}
      />
    </Tabs>
  );
}

function TabLabel({
  name,
  count,
}: {
  name: string;
  count: number;
}): JSX.Element {
  const { t } = useTranslation("units");

  return (
    <span>
      {name}{" "}
      <span style={{ fontSize: "12px", lineHeight: "14.52px" }}>
        ({t("number", { count })})
      </span>
    </span>
  );
}
