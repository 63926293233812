import React from "react";
import { ApolloCache, useTranslation, ApolloError } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import {
  GetProjectSitemapsDocument,
  GetProjectSitemapsQuery,
  SitemapStatus,
  useAddSitemapsMutation,
  useDisableSitemapMutation,
  useDisableSitemapsMutation,
  useEnableSitemapMutation,
  useEnableSitemapsMutation,
  useGetProjectSitemapsQuery,
  Sitemap as APISitemap,
  AddSitemapsMutation,
} from "../../../../graphql";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";

export interface Sitemap {
  url: string;
  urlDigest: string;
  malformed: boolean;
  enabled: boolean;
}

export interface SitemapsResult {
  sitemaps: Sitemap[];
  loading: boolean;
  setStatus: (sitemap: Sitemap | Sitemap[] | "all", status: boolean) => void;
  addSitemap: (urls: string[]) => Promise<boolean>;
}

export function useSitemaps(): SitemapsResult {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation("crawlSettings");

  const { enqueueSnackbar } = useSnackbar();
  function onError(title: string, error: ApolloError): void {
    enqueueSnackbar(<ApolloErrorSnackbar title={title} error={error} />);
  }

  const { data, loading } = useGetProjectSitemapsQuery({
    variables: {
      projectId,
    },
    fetchPolicy: "cache-first",
    onError: () => {
      // Ignoring the error message because serpent crawler does not support sitemaps discovery.
    },
  });

  const [enableSitemapMutation] = useEnableSitemapMutation({
    onError: (error) =>
      onError(t("sources.sitemaps.errorEnableSitemap"), error),
  });

  const [disableSitemapMutation] = useDisableSitemapMutation({
    onError: (error) =>
      onError(t("sources.sitemaps.errorDisableSitemap"), error),
  });

  const [enableSitemapsMutation] = useEnableSitemapsMutation({
    onError: (error) =>
      onError(t("sources.sitemaps.errorEnableSitemaps"), error),
  });

  const [disableSitemapsMutation] = useDisableSitemapsMutation({
    onError: (error) =>
      onError(t("sources.sitemaps.errorDisableSitemaps"), error),
  });

  const [addSitemapsMutation] = useAddSitemapsMutation({
    onError: (error) => onError(t("sources.sitemaps.errorAddSitemaps"), error),
  });

  const sitemaps =
    // eslint-disable-next-line fp/no-mutating-methods
    data?.getProject?.sitemaps
      .map((sitemap) => ({
        url: sitemap.url,
        urlDigest: sitemap.urlDigest,
        enabled: sitemap.enabled,
        malformed: sitemap.status === SitemapStatus.Malformed,
      }))
      .sort((a, b) => Number(a.url > b.url)) ?? [];

  function setStatus(
    value: Sitemap | Sitemap[] | "all",
    status: boolean,
  ): void {
    if (value === "all" || Array.isArray(value)) {
      setSitemapsStatus({
        sitemaps: value,
        allSitemaps: sitemaps,
        status,
        projectId,
        enableSitemapsMutation,
        disableSitemapsMutation,
      });
    } else {
      setSitemapStatus({
        sitemap: value,
        status,
        projectId,
        enableSitemapMutation,
        disableSitemapMutation,
      });
    }
  }

  return {
    sitemaps,
    loading,
    setStatus,
    addSitemap: (urls) =>
      addSitemaps({ urls, sitemaps, projectId, addSitemapsMutation }),
  };
}

function setSitemapStatus(props: {
  sitemap: Sitemap;
  projectId: string;
  status: boolean;
  enableSitemapMutation: ReturnType<typeof useEnableSitemapMutation>[0];
  disableSitemapMutation: ReturnType<typeof useDisableSitemapMutation>[0];
}): void {
  const opptimisticResponse = {
    sitemaps: [getOptimisticResponse(props.sitemap, props.status)],
  };

  if (props.status) {
    props.enableSitemapMutation({
      variables: {
        projectId: props.projectId,
        urlDigest: props.sitemap.urlDigest,
      },
      optimisticResponse: {
        enableSitemap: opptimisticResponse,
      },
    });
  } else {
    props.disableSitemapMutation({
      variables: {
        projectId: props.projectId,
        urlDigest: props.sitemap.urlDigest,
      },
      optimisticResponse: {
        disableSitemap: opptimisticResponse,
      },
    });
  }
}

function setSitemapsStatus(props: {
  sitemaps: "all" | Sitemap[];
  allSitemaps: Sitemap[];
  projectId: string;
  status: boolean;
  enableSitemapsMutation: ReturnType<typeof useEnableSitemapsMutation>[0];
  disableSitemapsMutation: ReturnType<typeof useDisableSitemapsMutation>[0];
}): void {
  const urlDigests =
    props.sitemaps === "all"
      ? undefined
      : props.sitemaps.map((x) => x.urlDigest);
  const opptimisticResponse = {
    sitemaps: (props.sitemaps === "all"
      ? props.allSitemaps
      : props.sitemaps
    ).map((sitemap) => getOptimisticResponse(sitemap, props.status)),
  };

  if (props.status) {
    props.enableSitemapsMutation({
      variables: {
        projectId: props.projectId,
        urlDigests,
      },
      optimisticResponse: {
        enableSitemaps: opptimisticResponse,
      },
    });
  } else {
    props.disableSitemapsMutation({
      variables: {
        projectId: props.projectId,
        urlDigests,
      },
      optimisticResponse: {
        disableSitemaps: opptimisticResponse,
      },
    });
  }
}

async function addSitemaps(props: {
  urls: string[];
  sitemaps: Sitemap[];
  projectId: string;
  addSitemapsMutation: ReturnType<typeof useAddSitemapsMutation>[0];
}): Promise<boolean> {
  const urlsToAdd: string[] = [];
  const urlsToEnable: Sitemap[] = [];

  props.urls.forEach((url) => {
    const sitemap = props.sitemaps.find((x) => x.url === url);
    if (sitemap) {
      // eslint-disable-next-line fp/no-mutating-methods
      urlsToEnable.push(sitemap);
    } else {
      // eslint-disable-next-line fp/no-mutating-methods
      urlsToAdd.push(url);
    }
  });

  const addUrls = Boolean(urlsToAdd.length);
  const enableUrls = Boolean(urlsToEnable.length);

  const optimisticResponse = {
    addCustomSitemaps: addUrls
      ? {
          sitemaps: [
            ...props.sitemaps.map((sitemap) =>
              getOptimisticResponse(sitemap, sitemap.enabled),
            ),
            ...urlsToAdd.map((url) => ({
              urlDigest: uuid(),
              url: url,
              enabled: true,
              status: SitemapStatus.Valid,
              __typename: "Sitemap",
            })),
          ],
        }
      : undefined,
    enableSitemaps: enableUrls
      ? {
          sitemaps: urlsToEnable.map((sitemap) =>
            getOptimisticResponse(sitemap, true),
          ),
        }
      : undefined,
  };

  const result = await props.addSitemapsMutation({
    variables: {
      projectId: props.projectId,
      addUrls,
      urlsToAdd,
      enableUrls,
      urlsToEnable: urlsToEnable.map((x) => x.urlDigest),
    },
    optimisticResponse,
    update: (cache, { data }) =>
      updateSitemapsUrls(cache, data, props.projectId),
  });

  return !result.errors;
}

function getOptimisticResponse(
  sitemap: Sitemap,
  enabled: boolean,
): Pick<APISitemap, "url" | "urlDigest" | "status" | "enabled"> & {
  __typename: string;
} {
  return {
    urlDigest: sitemap.urlDigest,
    url: sitemap.url,
    enabled,
    status: sitemap.malformed ? SitemapStatus.Malformed : SitemapStatus.Valid,
    __typename: "Sitemap",
  };
}

function updateSitemapsUrls(
  cache: ApolloCache<AddSitemapsMutation>,
  data: AddSitemapsMutation | null | undefined,
  projectId: string,
): void {
  const cachedData: GetProjectSitemapsQuery | null = cache.readQuery({
    query: GetProjectSitemapsDocument,
    variables: { projectId },
  });
  if (!cachedData?.getProject || !data?.addCustomSitemaps?.sitemaps) return;

  cache.writeQuery({
    query: GetProjectSitemapsDocument,
    variables: { projectId },
    data: {
      getProject: {
        ...cachedData.getProject,
        sitemaps: data.addCustomSitemaps.sitemaps,
      },
    },
  });
}
