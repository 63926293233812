import {
  DownloadSolid,
  SearchOutlined,
  Select,
  TextField,
  ToggleIconButton,
  useDateFormatter,
  useTranslation,
} from "@lumar/shared";
import {
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { isEqual } from "lodash";
import React from "react";
import { CustomSkeleton } from "../../_common/CustomSkeleton";
import { Metric, Metrics, ResourceDetailData } from "../data/types";
import { useGetCompareCrawlData } from "./data/useGetCompareCrawlData";
import { LoadingOverlay, MetricTableOverlay } from "./MetricTableOverlay";
import { useCrawlsListData } from "./data/useCrawlsListData";
import { MetricsValuePresenter } from "../metrics-value-presenter/MetricsValuePresenter";
import { useChangedMetricsOverlay } from "./useChangedMetricsOverlay";
import { useExportCSV } from "./useExportCSV";
import { metricCompareFn } from "./metricCompareFn";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.625),
    minWidth: 540,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  search: { maxWidth: 290 },
  icon: {
    fontSize: theme.typography.pxToRem(22),
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(1),
    minHeight: 373,
    overflowX: "clip",
  },
  header: {
    background: "#EAEDF0",
    height: theme.spacing(6),
  },
  row: {
    height: "auto",
    "& .LumarJSONTable": {
      maxHeight: 400,
      overflowY: "auto",
    },
  },
  nameCell: {
    padding: theme.spacing(1.25, 3),
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
    verticalAlign: "top",
  },
  valueCell: {
    padding: theme.spacing(1.25, 3),
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
  },
}));

export function ChangedMetricsTable({
  metrics,
  sourceTemplate,
  currentCrawlDate,
  exportFileName,
  filter,
}: {
  metrics: Metrics;
  sourceTemplate: ResourceDetailData["sourceTemplate"];
  currentCrawlDate: Date;
  exportFileName?: () => string;
  filter?: (metric: Metric) => boolean;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  const classes = useStyles();
  const formatDate = useDateFormatter();

  const exportCSV = useExportCSV();

  const [compareCrawlIdInner, setCompareCrawlIdInner] = React.useState<
    string | undefined
  >();

  const [search, setSearch] = React.useState("");
  const formattedSearch = search.toLocaleLowerCase();

  const {
    loading: crawlsLoading,
    error: crawlsError,
    data: crawlsData,
  } = useCrawlsListData({ currentCrawlDate });

  const {
    loading: crawlLoading,
    error: crawlError,
    data: crawlData,
  } = useGetCompareCrawlData({
    currentCrawlDate,
    sourceTemplate,
    crawlId: compareCrawlIdInner,
  });

  const compareCrawlId =
    compareCrawlIdInner ||
    crawlData?.crawlId ||
    crawlsData?.crawls.find(
      (x) => x.createdAt.getTime() < currentCrawlDate.getTime(),
    )?.id;

  const compareCrawlDate = crawlsData?.crawls.find(
    (x) => x.id === compareCrawlId,
  )?.createdAt;

  // eslint-disable-next-line fp/no-mutating-methods
  const changedMetrics = Object.values(metrics)
    .filter((metric) => metric.data && (!filter || filter(metric)))
    .filter(
      (metric) =>
        !isEqual(
          metric.value ?? "",
          crawlData?.metrics[metric.code]?.value ?? "",
        ),
    )
    .sort(metricCompareFn);

  const filteredMetrics = changedMetrics.filter(
    ({ code, name }) =>
      code.toLocaleLowerCase().includes(formattedSearch) ||
      name.toLocaleLowerCase().includes(formattedSearch),
  );

  const overlay = useChangedMetricsOverlay({
    error: crawlsError || crawlError,
    noCrawls: crawlsData && !crawlsData.crawls.length,
    noChanges: !changedMetrics.length,
    noMetrics: !filteredMetrics.length,
    crawlId: compareCrawlId,
  });

  function exportData(): void {
    if (!exportFileName || !compareCrawlDate) return;
    exportCSV({
      data: filteredMetrics.map((metric) => ({
        code: metric.code,
        values: [metric.value, crawlData?.metrics[metric.code].value],
        metricData: metric.data,
      })),
      crawlDates: [currentCrawlDate, compareCrawlDate],
      fileName: exportFileName(),
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t("searchMetricsPlaceholder")}
          className={classes.search}
          InputProps={{
            startAdornment: <SearchOutlined className={classes.icon} />,
          }}
        />
        {exportFileName && (
          <ToggleIconButton
            size="large"
            variant="outlined"
            onClick={exportData}
            disabled={crawlLoading || Boolean(overlay)}
          >
            <DownloadSolid />
          </ToggleIconButton>
        )}
      </div>
      <TableContainer className={classes.table}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="25%" className={classes.header}>
                <Typography>{t("metric")}</Typography>
              </TableCell>
              <TableCell width="37.5%" className={classes.header}>
                {formatDate(currentCrawlDate, {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
              </TableCell>
              <TableCell width="37.5%" className={classes.header}>
                {crawlsLoading ? (
                  <CustomSkeleton height={36} style={{ maxWidth: 260 }} />
                ) : crawlsData?.crawls.length ? (
                  <Select
                    value={compareCrawlId || ""}
                    onChange={(e) =>
                      setCompareCrawlIdInner(e.target.value as string)
                    }
                    displayEmpty
                    dividerRight
                  >
                    <MenuItem value="" disabled>
                      {t("selectCrawl")}
                    </MenuItem>
                    {crawlsData.crawls.map((crawl) => (
                      <MenuItem key={crawl.id} value={crawl.id}>
                        {formatDate(crawl.createdAt, {
                          dateStyle: "medium",
                          timeStyle: "short",
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {crawlLoading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <LoadingOverlay />
                </TableCell>
              </TableRow>
            ) : overlay ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <MetricTableOverlay {...overlay} />
                </TableCell>
              </TableRow>
            ) : (
              filteredMetrics.map(({ code, name, description }) => (
                <TableRow key={code} className={classes.row}>
                  <TableCell className={classes.nameCell}>
                    <Tooltip title={description || ""}>
                      <Typography variant="inherit">{name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.valueCell}>
                    <MetricsValuePresenter
                      metrics={metrics}
                      code={code}
                      componentProps={{
                        showCopy: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.valueCell}>
                    <MetricsValuePresenter
                      metrics={crawlData?.metrics || {}}
                      code={code}
                      componentProps={{
                        showCopy: true,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
