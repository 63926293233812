import React, { useRef, useState, useEffect } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { FastField, FastFieldProps, Formik, FormikProps } from "formik";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { getRawCrawlId, Snackbar } from "@lumar/shared";
import { useSnackbar } from "notistack";

import { Routes } from "../../../_common/routing/routes";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { SettingsBasic } from "./SettingsBasic";
import { SettingsAdvanced } from "./SettingsAdvanced";
import { FormValues, ValidationErrors } from "./data/types";
import { ScheduleSettings } from "./schedule/ScheduleSettings";
import { useHashParam } from "../../../_common/routing/useHashParams";
import { FormSubmitContext } from "../components/FormSubmitContext";
import { SettingsContext } from "./data/useContextValues";
import { useSettingsFormValues } from "./data/useSettingsFormValues";
import { useSettingsValidationSchema } from "./data/useSettingsValidationSchema";
import { useAdvancedControls } from "./useAdvancedControls";
import {
  formatValidationError,
  ValidationErrorDialog,
} from "./ValidationErrorDialog";
import { SettingsErrorOverlay } from "../components/SettingsErrorOverlay";
import { ProjectPreviewDialog } from "../project-preview/ProjectPreviewDialog";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  containerAdvanced: {
    width: "100%",
  },
}));

export function Settings(): JSX.Element {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  const { enqueueSnackbar } = useSnackbar();

  const showAdvanced = useSearchParam("advanced");

  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const history = useHistory();

  const formikRef = useRef<FormikProps<FormValues> | null>(null);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrors | undefined
  >(undefined);
  const [previewData, setPreviewData] = React.useState<
    { projectId: string; url: string } | undefined
  >();

  const { formValues, contextValues, loading, error, updateProject } =
    useSettingsFormValues();

  const crawlId = getRawCrawlId(
    contextValues.lastFinishedCrawlId ? contextValues.lastFinishedCrawlId : "0",
  );

  const validationSchema = useSettingsValidationSchema(contextValues);

  const advancedSettings = useAdvancedControls(contextValues.module.code);

  const advancedRef = useRef<HTMLDivElement>(null);
  const scrollToAdvanced = useHashParam("advanced-settings");
  useEffect(() => {
    if (!loading && scrollToAdvanced) {
      advancedRef.current?.scrollIntoView();
    }
  }, [loading, scrollToAdvanced]);

  if (loading) {
    return (
      <Grid container style={{ height: "208px" }} alignContent="center">
        <Grid item style={{ margin: "auto" }}>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <SettingsErrorOverlay title={t("errorLoadFailed")} description={error} />
    );
  }

  function showApiValidationErrors(): void {
    if (
      formikRef.current?.errors &&
      Object.keys(formikRef.current?.errors).length
    ) {
      setValidationErrors(
        formatValidationError(formikRef.current?.errors, advancedSettings),
      );
    }
  }

  async function handleSave(submitForm: () => Promise<boolean>): Promise<void> {
    const saved = await submitForm();
    if (!saved) {
      showApiValidationErrors();
      return;
    }

    enqueueSnackbar(
      <Snackbar
        variant="success"
        title={t("message.saveProjectDescription", {
          project: contextValues.projectName,
        })}
      />,
    );
  }

  async function handleSaveAndPreview(
    submitForm: () => Promise<boolean>,
    primaryDomain: string,
  ): Promise<void> {
    const saved = await submitForm();
    if (!saved) {
      showApiValidationErrors();
      return;
    }

    enqueueSnackbar(
      <Snackbar
        variant="success"
        title={t("message.saveProjectDescription", {
          project: contextValues.projectName,
        })}
      />,
    );

    setPreviewData({
      projectId,
      url: primaryDomain,
    });
  }

  async function handleFinalise(
    submitForm: () => Promise<boolean>,
  ): Promise<void> {
    const saved = await submitForm();
    if (!saved) {
      showApiValidationErrors();
      return;
    }

    history.push(
      Routes.Crawls.getUrl({
        accountId,
        projectId,
        tab: "progress",
        start: true,
      }),
    );
  }

  return (
    <SettingsContext.Provider value={contextValues}>
      <ValidationErrorDialog validationErrors={validationErrors} />
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={updateProject}
        validationSchema={validationSchema}
        innerRef={(formik) => (formikRef.current = formik)}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          margin="16px"
        >
          <FormSubmitContext>
            {({ submitForm, isSubmitting }) => (
              <Box
                display="flex"
                marginBottom="20px"
                data-testid="buttons-container"
              >
                {(!contextValues.hasCrawlInProgress ||
                  contextValues.hasCrawlUnarchiving) && (
                  <Button
                    onClick={() =>
                      handleFinalise(submitForm as () => Promise<boolean>)
                    }
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    data-testid="crawl-settings-save-and-start"
                    data-pendo="start-crawling-button"
                    disabled={isSubmitting}
                  >
                    {t("settings.startCrawling")}
                  </Button>
                )}
                {contextValues.hasCrawlInProgress &&
                  !contextValues.hasCrawlUnarchiving && (
                    <Button
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      to={Routes.Crawls.getUrl({
                        accountId,
                        projectId: projectId ?? "0",
                        tab: "progress",
                      })}
                      className={classes.button}
                      data-testid="btn-view-crawl"
                    >
                      {contextValues.hasPausedCrawl
                        ? t("settings.viewPausedCrawl")
                        : t("settings.viewCrawlProgress")}
                    </Button>
                  )}
                <Button
                  onClick={() =>
                    handleSave(submitForm as () => Promise<boolean>)
                  }
                  disabled={
                    isSubmitting ||
                    (contextValues.hasCrawlInProgress &&
                      !contextValues.hasCrawlUnarchiving)
                  }
                  variant="outlined"
                  className={classes.button}
                  data-testid="crawl-settings-save"
                >
                  {t("settings.saveSettings")}
                </Button>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={Routes.SegmentManager.getUrl({
                    accountId,
                    projectId,
                    crawlId,
                  })}
                  className={classes.button}
                  data-testid="btn-my-segments"
                >
                  {t("settings.mySegments")}
                </Button>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={Routes.ReportAdjustment.getUrl({
                    accountId,
                    projectId,
                    crawlId,
                  })}
                  className={classes.button}
                  data-testid="btn-report-adjustment"
                >
                  {t("settings.reportAdjustment")}
                </Button>
              </Box>
            )}
          </FormSubmitContext>
          <SettingsBasic />
          <FastField
            shouldUpdate={(
              { formik: newProps }: { formik: FormikProps<FormValues> },
              { formik: oldProps }: { formik: FormikProps<FormValues> },
            ) => newProps.isSubmitting !== oldProps.isSubmitting}
          >
            {({ form: { isSubmitting } }: FastFieldProps<FormValues>) => (
              <ScheduleSettings disabled={isSubmitting} />
            )}
          </FastField>
          <div ref={advancedRef} className={classes.containerAdvanced}>
            <SettingsAdvanced
              advancedSettings={advancedSettings}
              initialState={showAdvanced === "true"}
              handleSave={handleSave}
              handleFinalise={handleFinalise}
              handleSaveAndPreview={handleSaveAndPreview}
            />
          </div>
        </Box>
      </Formik>
      <ProjectPreviewDialog
        open={Boolean(previewData)}
        onClose={() => setPreviewData(undefined)}
        projectId={previewData?.projectId || ""}
        url={previewData?.url || ""}
        moduleCode={contextValues.module.code}
      />
    </SettingsContext.Provider>
  );
}
