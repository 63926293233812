import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, useTranslation } from "@lumar/shared";
import { FixedSizeList } from "react-window";

import { SourcesContext } from "../data/useSourcesFormValues";
import { GoogleAnalytics4Property } from "../data/types";

const useStyles = makeStyles((theme) => ({
  accouuntName: {
    fontSize: "0.714rem",
  },
  divider: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[200],
  },
}));

interface Props {
  propertyId?: string;
  onChange: (propertyId?: string) => void;
  filter?: string;
  disabled?: boolean;
}

export function GoogleAnalytics4List({
  propertyId,
  onChange,
  filter,
  disabled,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const { googleAnalytics4Properties } = React.useContext(SourcesContext);

  const properties: GoogleAnalytics4Property[] = [
    {
      propertyId: "none",
      connectionId: "",
      name: t("sources.analytics.googleAnalytics4.noneSelected"),
      accountName: t("sources.analytics.googleAnalytics4.none"),
      available: true,
    },
    ...googleAnalytics4Properties,
  ];

  const formattedFilter = (filter || "").toLowerCase();

  const filteredProperties = properties.filter(
    (x) => x.available && x.name.toLowerCase().includes(formattedFilter),
  );

  return (
    <RadioGroup
      value={propertyId || "none"}
      onChange={(_, value) => onChange(value === "none" ? undefined : value)}
    >
      <FixedSizeList
        width="100%"
        height={355}
        itemSize={46}
        itemCount={filteredProperties.length}
        itemData={{ properties: filteredProperties }}
      >
        {({ index, data, style }) => {
          const property = data.properties[index];
          return (
            <FormControlLabel
              key={property.propertyId}
              value={property.propertyId}
              style={style}
              control={<Radio />}
              disabled={disabled}
              label={
                <div style={{ padding: "4px 0" }}>
                  <Typography
                    variant="captionSemiBold"
                    data-testid="crawl-settings-ga4-name"
                  >
                    {property.name}
                  </Typography>
                  <Typography
                    className={classes.accouuntName}
                    variant="subtitle4"
                    data-testid="crawl-settings-ga4-account-name"
                    component="div"
                    color="primary"
                  >
                    {property?.accountName}
                  </Typography>
                </div>
              }
              data-testid="crawl-settings-ga4-available-properties"
              className={classes.divider}
            />
          );
        }}
      </FixedSizeList>
    </RadioGroup>
  );
}
