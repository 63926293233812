/* eslint-disable fp/no-mutating-methods */
import { getRawCrawlId, useQuery } from "@lumar/shared";
import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import {
  ColumnState,
  convertColumnsParamFrom,
  getGridColumnsParam,
  readColumnsState,
  writeColumnsState,
} from "../../../../_common/data-grid/column-persistance/columnsState";
import { filterReportMetrics } from "../../../../_common/report-metrics/filterReportMetrics";
import { useURLSearchParams } from "../../../../_common/routing/useURLSearchParams";
import {
  CustomMetricType,
  CustomReportColumnsMetadataQuery,
  CustomReportColumnsMetadataQueryResult,
  Metric,
  MetricType,
  ModuleCode,
  ReportStatColumnsMetadataQuery,
  ReportStatColumnsMetadataQueryResult,
} from "../../../../graphql";
import { convertCutomMetricTypeToMetricType } from "../../../../resource-detail/data/getMetrics";
import { createPersistenceKeyFromReportInput } from "../../../Report.helpers";
import { MetricData } from "../ReportGrid.types";
import {
  DefaultColumnState,
  DefaultReportGridColumnsResult,
  ReportGridColumn,
  UseDefaultReportGridColumnsArgs,
} from "./DefaultReportGridColumns.types";
import { getReportGridColumnsMetadataQuery } from "./ReportGridColumns.helpers";
import { sortableMetrics } from "./sortableMetrics";
import { useState } from "react";
import { GridState } from "@mui/x-data-grid-pro";

export function useDefaultReportGridColumns({
  reportInput,
  overwrites,
  columnPersistanceKey,
}: UseDefaultReportGridColumnsArgs): DefaultReportGridColumnsResult {
  // This is necessary to be able to react to the grid state change
  // as right now the grid state is updated using a ref directly
  // making this change not reactive. We need to listen for a change,
  // as other external side effects or state (like pagination) might
  // still consumed the memoized value if there exist a race condition,
  // as in many places we are debouncing the state change.
  const [updatedGridState, setUpdatedGridState] = useState<
    GridState | undefined
  >();

  const { document, variables } = getReportGridColumnsMetadataQuery({
    reportInput,
  });

  const { loading, data, error } = useQuery<
    | ReportStatColumnsMetadataQueryResult["data"]
    | CustomReportColumnsMetadataQueryResult["data"]
  >(document, {
    variables,
    fetchPolicy: "cache-first",
  });

  const params = useURLSearchParams();
  const columnsParam = params.get("columns");
  const viewTypeParam = params.get("viewType");

  const persistenceKeyFromInput =
    createPersistenceKeyFromReportInput(reportInput);

  const [viewType, setViewType] = useLocalStorageState<string>(
    `${persistenceKeyFromInput}_view_type`,
  );
  const isGridView = (viewTypeParam ?? viewType) === "grid";

  const memoed = useMemo(() => {
    const reportTemplate = data?.report?.reportTemplate;
    const datasourceCode = reportTemplate?.datasource?.datasourceCode;

    const isCrawlIncomplete = data?.getCrawl?.incomplete;
    const compareToCrawlId = data?.getCrawl?.comparedToCrawlId
      ? getRawCrawlId(data.getCrawl.comparedToCrawlId)
      : undefined;

    const metricsData = getMetrics(data);
    const metricsGroupings =
      (overwrites?.metricsGroupings ?? reportTemplate?.metricsGroupings)?.map(
        (group) => ({
          metrics: group.metrics.reduce<MetricData[]>((result, { code }) => {
            const metric = metricsData.find((x) => x.code === code);
            return metric ? [...result, metric] : result;
          }, []),
        }),
      ) || [];

    const customExtractions =
      data?.getCrawl?.crawlSetting?.customExtractions?.map((x) => ({
        code: x.reportTemplateCode.replaceAll("_", "").toLowerCase(),
        label: x.label,
      })) ?? [];
    function getCustomName(code: string): string | undefined {
      if (!code.startsWith("customExtraction")) return;
      return customExtractions.find((x) => x.code === code.toLowerCase())
        ?.label;
    }

    // Those indexes have an order guaranteed by the api.
    const cardMetricsIndex = 0;
    const foundInSourcesIndex = 3;

    const cardMetrics =
      metricsGroupings[cardMetricsIndex]?.metrics.map((x) => ({
        ...x,
        name: getCustomName(x.code) ?? x.name,
      })) ?? []; // for card

    const foundInSources = metricsGroupings[foundInSourcesIndex]?.metrics ?? []; // for card

    const defaultMetrics =
      metricsGroupings
        ?.filter((_v, i) => {
          if (isGridView) return true;
          return i !== cardMetricsIndex && i !== foundInSourcesIndex;
        })
        .map((g) => g.metrics)
        .flat() ?? [];

    const all = [
      ...defaultMetrics,
      ...metricsData.filter(
        (m) => !defaultMetrics.find((d) => d.code === m.code),
      ),
    ].map((x) => ({ ...x, name: getCustomName(x.code) ?? x.name }));

    const filterMetrics = getFilterMetrics(metricsData, customExtractions);

    const defaultStates = [
      ...(!isGridView
        ? [{ code: "card", hide: false, width: 594, minWidth: 594 }]
        : []),
      ...all.map((col) => ({
        code: col.code,
        hide: getDefaultHidden(col, defaultMetrics),
        ...getDefaultProps(col, datasourceCode || "", col.type),
      })),
    ];

    const storedStates = readColumnsState(
      columnPersistanceKey ?? persistenceKeyFromInput,
      defaultStates,
      getColumnParam(
        cardMetrics.map((x) => x.code),
        isGridView,
      ),
      isGridView,
    );

    const possibleMetrics = storedStates
      ? [...all].sort(
          ({ code: a }, { code: b }) =>
            storedStates.findIndex((x) => x.code === a) -
            storedStates.findIndex((x) => x.code === b),
        )
      : all;

    return {
      possibleMetrics,
      defaultMetrics,
      cardMetrics,
      foundInSources,
      storedStates,
      defaultStates,
      isCrawlIncomplete,
      compareToCrawlId,
      metricsData,
      filterMetrics,
      datasourceCode,
      crawlTypesMetadata: data?.getCrawlTypesMetadata ?? [],
      updatedGridState,
    };
  }, [
    data,
    persistenceKeyFromInput,
    isGridView,
    columnPersistanceKey,
    overwrites?.metricsGroupings,
    updatedGridState,
  ]);

  return {
    loading,
    error,
    ...memoed,
    saveColumnsState: (state) => {
      if (!columnsParam) {
        writeColumnsState(
          columnPersistanceKey ?? persistenceKeyFromInput,
          state,
          memoed.defaultStates,
          isGridView,
        );
      } else {
        const columns = getGridColumnsParam(state, memoed.defaultStates);
        if (columns && columns !== params.get("columns")) {
          params.set("columns", columns);
          params.apply();
        }
      }
      setUpdatedGridState(state);
    },
    isGridView,
    toggleGridView: () => {
      if (!viewTypeParam) {
        setViewType((x) => (x !== "grid" ? "grid" : "table"));
      } else {
        params.set("viewType", isGridView ? "table" : "grid");
        params.apply();
      }
    },
  };
}

function getDefaultHidden(
  metric: Pick<Metric, "code">,
  defualtMetrics: Pick<Metric, "code">[],
): boolean {
  return !defualtMetrics.find((x) => x.code === metric.code);
}

function getDefaultProps(
  metric: ReportGridColumn,
  datasourceCode: string,
  type: MetricType,
): Omit<DefaultColumnState, "code" | "hide"> {
  const props = defaultColumnProps[metric.code];
  return {
    width: props?.width ?? metric.name.length * 7 + 80,
    minWidth: props?.minWidth ?? (type === MetricType.String ? 200 : 100),
    sortable:
      metric.isCustomMetricSortable ||
      sortableMetrics[datasourceCode]?.get(metric.code) ||
      false,
    ...props,
  };
}

const defaultColumnProps: Record<
  string,
  Partial<Omit<DefaultColumnState, "code" | "hide">>
> = {
  rawHeader: { width: 300, minWidth: 300 },
  ...Object.fromEntries(
    Array.from(
      [...Array(30).keys()].map((x) => [
        `customExtraction${x}`,
        { width: 300, minWidth: 300 },
      ]),
    ),
  ),
  containerExecutionFailures: { minWidth: 200 },
};

function getFilterMetrics(
  metrics: MetricData[],
  customExtractions: {
    code: string;
    label: string;
  }[],
): MetricData[] {
  const formattedMetrics = metrics.map((metric) => {
    const customExtraction = customExtractions.find(
      (x) => x.code === metric.code.toLowerCase(),
    );

    if (!customExtraction) return metric;

    return {
      ...metric,
      name: customExtraction.label,
      description: metric.description.replace(
        metric.name,
        customExtraction.label,
      ),
    };
  });

  return filterReportMetrics<MetricData>(
    formattedMetrics,
    customExtractions.map((x) => x.code),
  );
}

function getMetrics(
  data:
    | ReportStatColumnsMetadataQuery
    | CustomReportColumnsMetadataQuery
    | undefined,
): MetricData[] {
  function getSortable(type: CustomMetricType | null | undefined): boolean {
    switch (type) {
      case CustomMetricType.Boolean:
      case CustomMetricType.Integer:
      case CustomMetricType.Number:
      case CustomMetricType.String:
        return true;
      default:
        return false;
    }
  }

  const metrics = data?.report?.reportTemplate.datasource.metrics || [];
  return metrics
    .flatMap((metric) => {
      if (metric.code !== "customMetrics") return [metric];

      const customMetricData = data?.getCrawl?.customMetrics || [];
      return customMetricData.map<MetricData>((x) => ({
        code: `customMetrics.${x.code}`,
        name: `${x.name} *`,
        description: "",
        type: convertCutomMetricTypeToMetricType(x.type),
        connectionPredicates: x.connectionPredicates ?? [],
        supportedModules: metric.supportedModules,
        isCustomMetricSortable: getSortable(x.type),
      }));
    })
    .filter((x) => supportedModuleFilter(x, data?.report?.project.moduleCode));
}

function supportedModuleFilter(
  metric: Pick<Metric, "supportedModules">,
  moduleCode?: ModuleCode,
): boolean {
  return Boolean(moduleCode && metric.supportedModules?.includes(moduleCode));
}

function getColumnParam(
  cardMetrics: string[],
  isGridView?: boolean,
): (Partial<ColumnState> | null)[] | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  const columnsParam = urlParams.get("columns");
  if (!columnsParam) return;

  const columns = convertColumnsParamFrom(columnsParam);
  if (!columns) return;

  return isGridView
    ? columns.flatMap((x) =>
        x?.code === "card" ? cardMetrics.map((code) => ({ code })) : [x],
      )
    : columns;
}
