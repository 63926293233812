import React from "react";
import { useTranslation, Snackbar, ApolloError } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { createContext } from "react";
import { useParams } from "react-router-dom";

import {
  useAddGoogleConnectionMutation,
  useGetCrawlSettingsForSourcesQuery,
} from "../../../../graphql";
import { ContextValues, FormValues } from "./types";
import { DefaultContextValues } from "./getContextValues";
import { getFormValues } from "./getFormValues";
import { getContextValues } from "./getContextValues";
import { useUpdateSourcesMutation } from "./useUpdateSourcesMutation";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";
import {
  getValidationErrors,
  useSettingsDialog,
} from "../../components/SettingsDialog";
import { useRollbar } from "@rollbar/react";

export interface FromValuesResult {
  formValues: FormValues;
  contextValues: ContextValues;
  loading: boolean;
  error?: string;
  updateSources: (values: FormValues) => Promise<boolean>;
}

export function useSourcesFormValues(): FromValuesResult {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();
  const { showMessage } = useSettingsDialog();
  const rollbar = useRollbar();

  const { data, error, loading } = useGetCrawlSettingsForSourcesQuery({
    variables: {
      projectId,
      accountId,
    },
    errorPolicy: "all",
  });

  const [addGoogleConnection] = useAddGoogleConnectionMutation({
    variables: {
      redirectUrl: window.location.href,
    },
    onCompleted: (response) => {
      const link = document.createElement("a");
      link.setAttribute("href", response.createGoogleConnection.authUrl);
      link.click();
      link.remove();
    },
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorAddGoogleAccount")}
          error={error}
        />,
      ),
  });

  const updateSources = useUpdateSourcesMutation();

  const { contextValues, formValues } = React.useMemo(() => {
    const contextValues = getContextValues(
      (message) =>
        enqueueSnackbar(<Snackbar variant="error" title={message} />),
      addGoogleConnection,
      data,
    );

    return {
      contextValues,
      formValues: getFormValues(data, contextValues),
    };
  }, [data, addGoogleConnection, enqueueSnackbar]);

  return {
    formValues,
    contextValues,
    loading,
    error: getError(error),
    updateSources: async (values: FormValues) => {
      try {
        await updateSources({
          variables: { projectId, values, initialValues: formValues, data },
          refetchQueries: ["ProjectSummary"],
          awaitRefetchQueries: true,
        });
        return true;
      } catch (error) {
        if (error instanceof ApolloError) {
          const errorMessage = getValidationErrors(error);
          if (errorMessage.length) {
            rollbar.error("Project sources settings validation error", error);
            showMessage({
              errorMessage,
              apiError: error,
              title: t("message.apiErrorTitleSave"),
            });
          } else {
            enqueueSnackbar(
              <ApolloErrorSnackbar
                title={t("message.apiErrorTitleSave")}
                error={error}
              />,
            );
          }
        } else {
          enqueueSnackbar(
            <Snackbar variant="error" title={t("message.apiErrorTitleSave")} />,
          );
        }
        return false;
      }
    },
  };
}

export const SourcesContext = createContext(DefaultContextValues);

function getError(error: ApolloError | undefined): string | undefined {
  if (!error) return;

  // Note: At the time of writing, the API does not currently provide the capability to
  // filter out invalid Google connections. However, this error does not impact other
  // connections, so it is safe to ignore this error. - Csaba
  const graphQLErrors = error.graphQLErrors.filter(
    (x) => x.extensions?.code !== "GOOGLE_CONNECTION_INVALID",
  );

  if (graphQLErrors[0]) return graphQLErrors[0].message;
  if (error.clientErrors?.[0]) return error.clientErrors[0].message;
  if (error.protocolErrors?.[0]) return error.protocolErrors[0].message;
  if (error.networkError) return error.networkError.message;
}
