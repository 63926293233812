import React from "react";
import {
  Snackbar,
  TimeZoneContext,
  useTranslation,
  isNonEmpty,
} from "@lumar/shared";
import { addMinutes, format as formatDate } from "date-fns";
import { isString } from "lodash";
import { useSnackbar } from "notistack";

import {
  ExportedRowsQuery,
  useGetExportedRowFileUrlLazyQuery,
} from "../../../../../graphql";
import { ExportedRows } from "./export-rows-item/ExportRowsItem";

export function useExportDownload({
  data,
  reportTypeCode,
}: {
  data: ExportedRowsQuery | undefined;
  reportTypeCode: string;
}): (download: ExportedRows[number]) => void {
  const { timeZone } = React.useContext(TimeZoneContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("report");

  function getFileName(filtered: boolean): string {
    const finishedAt = data?.report?.crawl.finishedAt
      ? new Date(data.report.crawl.finishedAt)
      : undefined;

    return getExportName({
      primaryDomain: data?.report?.crawl.crawlSetting?.primaryDomain,
      finishedAt: finishedAt
        ? addMinutes(
            finishedAt,
            timeZone.offset + finishedAt.getTimezoneOffset(),
          )
        : undefined,
      reportTemplateName: data?.report?.reportTemplate.name,
      reportTypeCode: reportTypeCode.toLowerCase(),
      filtered,
    });
  }

  const [getLink] = useGetExportedRowFileUrlLazyQuery({
    onCompleted: (data) => {
      const fileUrl = isNonEmpty(data.node) ? data.node?.fileURL : "";
      if (!fileUrl) return;

      const a = document.createElement("a");
      a.href = fileUrl;
      // eslint-disable-next-line fp/no-mutation
      a.download = "";
      a.click();
    },
    onError: () =>
      enqueueSnackbar(<Snackbar variant="error" title={t("downloadError")} />),
  });

  return (download) => {
    getLink({
      variables: {
        reportDownloadId: download.id,
        fileName: getFileName(Boolean(download.filter)),
      },
    });
  };
}

function getExportName({
  primaryDomain,
  finishedAt,
  reportTemplateName,
  reportTypeCode,
  filtered,
}: {
  primaryDomain?: string;
  finishedAt?: Date;
  reportTemplateName?: string;
  reportTypeCode: string;
  filtered: boolean;
}): string {
  const maxFileNameLength = 218;

  const fileNameWithoutDomain = [
    finishedAt ? formatDate(finishedAt, "dd-MM-yyyy") : undefined,
    reportTemplateName
      ? getFileSafeString(reportTemplateName, "_")
      : "undefined",
    reportTypeCode,
    filtered ? `filtered` : undefined,
  ]
    .filter(isString)
    .join("_");

  const projectDomain = primaryDomain
    ? getFileSafeDomain(primaryDomain).substr(
        0,
        maxFileNameLength - fileNameWithoutDomain.length,
      )
    : "";

  return `${projectDomain}_${fileNameWithoutDomain}`;
}

function getFileSafeDomain(primaryDomain: string): string {
  try {
    const hostname = new URL(primaryDomain).hostname;
    return getFileSafeString(hostname, "-");
  } catch {
    return "";
  }
}

function getFileSafeString(string: string, replacement: string): string {
  return string.replace(/\W+/g, replacement);
}
