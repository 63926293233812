import {
  fieldToSelect,
  Select,
  TextField,
  TimeZoneContext,
  ToggleIconButton,
  updateIfPropsChanged,
} from "@lumar/shared";
import {
  Button,
  ClickAwayListener,
  Grid,
  InputAdornment,
  MenuItem,
  Popper,
  TextField as MaterialTextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import { addMinutes } from "date-fns";
import {
  FastField,
  FastFieldProps,
  FieldArray,
  FieldProps,
  FormikErrors,
  getIn,
} from "formik";
import React, { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AdvancedCrawlRateDay, AdvancedCrawlRateType } from "../../../graphql";
import { translationNamespace } from "../CrawlSettings";
import { TimePicker } from "../settings/schedule/TimePicker";
import { crawlRateAdvanced, FormValues, SettingsContext } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    indent: {
      marginTop: theme.spacing(2),
    },
    grid: {},
    right: {
      marginLeft: "auto",
    },
    fullWidthRow: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    fullWidthColumn: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    removeBox: {},
    chip: { marginTop: theme.spacing(2) },
    advancedRate: {
      paddingLeft: "12px",
      marginLeft: "4px",
      paddingTop: "14px",
      paddingBottom: "14px",
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
    },
    advancedRateContainer: {
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: "4px",
      padding: "16px",
      marginBottom: "14px",
      width: "600px",
      boxShadow: "0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%)",
    },
    popper: {
      zIndex: theme.zIndex.drawer,
      background: "white",
      padding: theme.spacing(2),
      borderColor: theme.palette.grey[200],
      borderStyle: "solid",
      borderWidth: "1px",
    },
    pickerDiv: {
      display: "flex",
      flexDirection: "column",
    },
    pickerBtn: {
      marginTop: theme.spacing(2),
    },
  }),
);

export function LimitsRestrictions(): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const { t: tCommon } = useTranslation("common");
  const classes = useStyles();
  const { timeZone } = React.useContext(TimeZoneContext);

  const [showRestrictions, setShowRestrictions] = useState(false);

  const createNewGroup = (): crawlRateAdvanced => {
    return {
      type: AdvancedCrawlRateType.Daily,
      start: addMinutes(new Date("2012-12-17T00:00:00Z"), timeZone.offset), // Monday 00:00 in the active timezone
      end: addMinutes(new Date("2012-12-23T23:55:00Z"), timeZone.offset), // Sunday 23:55 in the active timezone
      crawlRate: "3",
    };
  };

  const handleRestrictionsOpen = (
    values: FormValues,
    push: (newValue: crawlRateAdvanced) => void,
  ): void => {
    const newValue = !showRestrictions;
    setShowRestrictions(newValue);

    if (newValue && !values.maximumCrawlRateAdvanced.length) {
      push(createNewGroup());
    }
  };

  const crawlRateOptions = [
    {
      value: undefined,
      name: t("limits.restrictionsRateType"),
    },
    {
      value: AdvancedCrawlRateType.Daily,
      name: t("limits.restrictionsRateDaily"),
    },
    {
      value: AdvancedCrawlRateType.Weekly,
      name: t("limits.restrictionsRateWeekly"),
    },
  ];

  const crawlRateDayOption = [
    {
      value: AdvancedCrawlRateDay.Monday,
      name: tCommon("dayOfWeek.monday"),
    },
    {
      value: AdvancedCrawlRateDay.Tuesday,
      name: tCommon("dayOfWeek.tuesday"),
    },
    {
      value: AdvancedCrawlRateDay.Wednesday,
      name: tCommon("dayOfWeek.wednesday"),
    },
    {
      value: AdvancedCrawlRateDay.Thursday,
      name: tCommon("dayOfWeek.thursday"),
    },
    {
      value: AdvancedCrawlRateDay.Friday,
      name: tCommon("dayOfWeek.friday"),
    },
    {
      value: AdvancedCrawlRateDay.Saturday,
      name: tCommon("dayOfWeek.saturday"),
    },
    {
      value: AdvancedCrawlRateDay.Sunday,
      name: tCommon("dayOfWeek.sunday"),
    },
  ];

  return (
    <FieldArray name="maximumCrawlRateAdvanced">
      {({ form: { values, isSubmitting }, push, remove }) => (
        <>
          <Grid container direction="row" alignItems="center">
            <Grid item style={{ width: "400px" }}>
              <FastField name="maximumCrawlRate">
                {({
                  field: { name, value },
                  form: { setFieldValue, isSubmitting, setFieldError, errors },
                }: FastFieldProps) => (
                  <CrawlRate
                    name="maximumCrawlRate"
                    minValue={0.3}
                    value={value}
                    setValue={(newValue) => setFieldValue(name, newValue)}
                    setError={(error) => setFieldError(name, error)}
                    errors={errors}
                    disabled={isSubmitting}
                    data-testid="crawl-rate"
                  />
                )}
              </FastField>
            </Grid>
            <Grid item style={{ marginLeft: "24px" }}>
              <Tooltip
                title={
                  <Trans
                    ns="crawlSettings"
                    i18nKey="limits.restrictionsTooltip"
                    values={{
                      activeRestrictions:
                        values.maximumCrawlRateAdvanced?.length ?? 0,
                    }}
                  />
                }
                placement="top"
                arrow
              >
                <Button
                  onClick={() => handleRestrictionsOpen(values, push)}
                  variant="outlined"
                  data-testid="limits-crawl-rate-advanced"
                  size="small"
                  startIcon={
                    values.maximumCrawlRateAdvanced?.length ? (
                      <SettingsIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                >
                  {values.maximumCrawlRateAdvanced?.length
                    ? showRestrictions
                      ? t("limits.hideRestrictions", {
                          count: values.maximumCrawlRateAdvanced.length,
                        })
                      : t("limits.showRestrictions", {
                          count: values.maximumCrawlRateAdvanced.length,
                        })
                    : t("limits.addRestriction")}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          {showRestrictions && (
            <div className={classes.advancedRate}>
              {values.maximumCrawlRateAdvanced.map(
                (_: unknown, idx: number) => {
                  const isWeekly =
                    values.maximumCrawlRateAdvanced[idx].type === "Weekly";
                  return (
                    <div key={idx} className={classes.advancedRateContainer}>
                      <Grid
                        container
                        spacing={1}
                        className={classes.grid}
                        alignItems="center"
                      >
                        <Grid item style={{ width: "170px" }}>
                          <FastField
                            name={`maximumCrawlRateAdvanced[${idx}].type`}
                          >
                            {(props: FieldProps<string>) => (
                              <Select
                                {...fieldToSelect(props)}
                                data-testid="advanced-crawl-rate-type"
                              >
                                {crawlRateOptions.map((option, idx) => (
                                  <MenuItem
                                    key={idx}
                                    value={option.value}
                                    disabled={!option.value}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </FastField>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={t("limits.restrictionsRemove")}
                            placement="top"
                            arrow
                          >
                            <div>
                              <ToggleIconButton
                                onClick={() => {
                                  if (
                                    values.maximumCrawlRateAdvanced.length === 1
                                  ) {
                                    setShowRestrictions(false);
                                  }
                                  remove(idx);
                                }}
                                disabled={isSubmitting}
                                variant="outlined"
                                size="medium"
                                colorVariant="red"
                                style={{ marginLeft: "auto" }}
                                data-testid="advanced-crawl-rate-remove"
                              >
                                <DeleteIcon />
                              </ToggleIconButton>
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid item style={{ width: "100%" }}></Grid>
                        <Grid
                          item
                          style={{ width: "50px", textAlign: "right" }}
                        >
                          {t("limits.restrictionFrom")}
                        </Grid>
                        <CrawlRateTimeSelector
                          name={`maximumCrawlRateAdvanced[${idx}].start`}
                          isWeekly={isWeekly}
                          timeZoneOffset={timeZone.offset}
                          crawlRateDayOption={crawlRateDayOption}
                          label={t("limits.restrictionsSelectStartDay")}
                        />
                        <Grid item style={{ width: "100%" }}></Grid>
                        <Grid
                          item
                          style={{ width: "50px", textAlign: "right" }}
                        >
                          {t("limits.restrictionTo")}
                        </Grid>
                        <CrawlRateTimeSelector
                          name={`maximumCrawlRateAdvanced[${idx}].end`}
                          isWeekly={isWeekly}
                          timeZoneOffset={timeZone.offset}
                          crawlRateDayOption={crawlRateDayOption}
                          label={t("limits.restrictionsSelectEndDay")}
                        />
                      </Grid>
                      <div style={{ marginTop: "15px", width: "100%" }}>
                        <FastField
                          name={`maximumCrawlRateAdvanced[${idx}].crawlRate`}
                        >
                          {({
                            field: { name, value },
                            form: {
                              setFieldValue,
                              setFieldError,
                              isSubmitting,
                              errors,
                            },
                          }: FastFieldProps) => (
                            <CrawlRate
                              name={`maximumCrawlRateAdvanced[${idx}].crawlRate`}
                              minValue={0.3}
                              value={value}
                              setValue={(newValue) =>
                                setFieldValue(name, newValue)
                              }
                              errors={errors}
                              setError={(error) => setFieldError(name, error)}
                              disabled={isSubmitting}
                              data-testid="advanced-crawl-rate-value"
                            />
                          )}
                        </FastField>
                      </div>
                    </div>
                  );
                },
              )}
              <Button
                onClick={() => push(createNewGroup())}
                variant="outlined"
                size="small"
                startIcon={<AddIcon />}
                disabled={isSubmitting}
                data-testid="advanced-crawl-rate-add"
              >
                {t("limits.addRestriction")}
              </Button>
            </div>
          )}
        </>
      )}
    </FieldArray>
  );
}

function CrawlRateTimeSelector({
  name,
  isWeekly,
  timeZoneOffset,
  crawlRateDayOption,
  label,
}: {
  name: string;
  isWeekly: boolean;
  timeZoneOffset: number;
  crawlRateDayOption: { value: AdvancedCrawlRateDay; name: string }[];
  label: string;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(translationNamespace);
  // Note: For simplicity the start and end time is stored in UTC timezone.
  // This requires conversion back and forth when the value displayed and changed on the UI. - Csaba
  function getDayOptionFrom(time: Date): string {
    // Converts time to day of week in the active timezone
    const day = addMinutes(time, timeZoneOffset).getUTCDay() - 1;
    const index =
      (day < 0 ? day + crawlRateDayOption.length : day) %
      crawlRateDayOption.length;
    return crawlRateDayOption[index].value;
  }

  function getDayOptionTo(day: string, time: Date): Date {
    // Converts day of week in the active timezone to utc date
    const minutes = time.getUTCHours() * 60 + time.getUTCMinutes();
    const dayInUTC =
      crawlRateDayOption.findIndex((x) => x.value === day) -
      Math.floor((minutes + timeZoneOffset) / (24 * 60));
    return addMinutes(
      new Date("2012-12-17T00:00:00Z"),
      dayInUTC * 24 * 60 + minutes,
    );
  }

  function show2Digits(value: number): string {
    return String(value).padStart(2, "0");
  }

  function getHoursAndMinutes(value: Date): string {
    return (
      show2Digits(value.getHours()) + ":" + show2Digits(value.getMinutes())
    );
  }

  const options = [
    {
      value: undefined,
      name: label,
    },
    ...crawlRateDayOption,
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? "time-picker-popover" : undefined;

  return (
    <>
      {isWeekly && (
        <Grid item>
          <FastField
            name={name}
            timeZoneOffset={timeZoneOffset}
            shouldUpdate={updateIfPropsChanged("timeZoneOffset")}
          >
            {({
              field: { value, name },
              form: { setFieldValue, isSubmitting },
            }: FastFieldProps<Date>) => (
              <Select
                value={getDayOptionFrom(value)}
                onChange={(e) => {
                  setFieldValue(
                    name,
                    getDayOptionTo(e.target.value as string, value),
                  );
                }}
                disabled={isSubmitting}
                data-testid="advanced-crawl-rate-start-day"
                style={{ width: "112px" }}
              >
                {options.map((option, idx) => (
                  <MenuItem
                    key={idx}
                    value={option.value}
                    disabled={!option.value}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FastField>
        </Grid>
      )}
      <Grid item>
        <FastField
          name={name}
          timeZoneOffset={timeZoneOffset}
          open={open}
          shouldUpdate={updateIfPropsChanged(["timeZoneOffset", "open"])}
        >
          {({
            field: { value, name },
            form: { setFieldValue },
          }: FastFieldProps<Date>) => (
            <>
              <MaterialTextField
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                value={getHoursAndMinutes(
                  addMinutes(value, value.getTimezoneOffset() + timeZoneOffset),
                )}
                variant="outlined"
              />
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setAnchorEl(null);
                  }}
                >
                  <div className={classes.pickerDiv}>
                    <TimePicker
                      // TimePicker shows the time value in the browser's timezone, in order to show the time
                      // in a different timezone, the value is adjusted to UTC then to the desired timezone. - Csaba
                      value={addMinutes(
                        value,
                        value.getTimezoneOffset() + timeZoneOffset,
                      )}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue(
                            name,
                            addMinutes(
                              value,
                              -value.getTimezoneOffset() - timeZoneOffset,
                            ),
                          );
                        }
                      }}
                      data-testid="advanced-crawl-rate-start-hour"
                    />
                    <Button
                      onClick={() => setAnchorEl(null)}
                      variant="outlined"
                      className={classes.pickerBtn}
                    >
                      {t("limits.cancel")}
                    </Button>
                  </div>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </FastField>
      </Grid>
    </>
  );
}

function CrawlRate({
  name,
  value,
  minValue,
  setValue,
  setError,
  disabled,
  errors,
  "data-testid": dataTestId,
}: {
  name: string;
  value: number;
  minValue: number;
  setValue: (value: string | undefined) => void;
  setError: (error?: string) => void;
  disabled: boolean;
  errors: FormikErrors<FormValues>;
  "data-testid"?: string;
}): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const contextValues = useContext(SettingsContext);

  const maxValue = contextValues.maxCrawlRate;

  return (
    <>
      <TextField
        inputProps={{
          type: "number",
          step: "0.1",
          min: minValue.toString(),
          max: maxValue.toString(),
        }}
        value={value}
        onChange={(e) => {
          const value = e.target.value;
          setValue(value);

          if (value !== "" || value !== undefined) {
            setError(undefined);
          }
        }}
        error={Boolean(getCrawlRateError(name, errors))}
        disabled={disabled || contextValues.projectIsInStealthMode}
        data-testid={dataTestId}
        data-pendo="crawl-speed-slider"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography>{t("limits.crawlSpeedUrls")}</Typography>
            </InputAdornment>
          ),
        }}
        helperText={getCrawlRateError(name, errors)}
      />
    </>
  );
}

const getCrawlRateError = (
  name: string,
  errors: FormikErrors<FormValues>,
): string | null => {
  const error = getIn(errors, name);
  return error ? error : null;
};
