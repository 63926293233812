import React from "react";
import { useDateFormatter, useTranslation, Chip } from "@lumar/shared";
import { makeStyles, Typography } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

const useStyles = makeStyles(() => ({
  outerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  text: { lineHeight: "20px", paddingLeft: 5, fontSize: 14 },
  badge: {
    marginLeft: "auto",
  },
}));

interface DateSinceCellProps {
  params: GridRenderCellParams;
  showTestSiteBadge?: boolean;
  "data-testid"?: string;
}

export function DateSinceCell(props: DateSinceCellProps): JSX.Element {
  const classes = useStyles();
  const dateFormatter = useDateFormatter();
  const isTestSite = props.params.row.crawlSetting.crawlTestSite;
  const showBadge = props.showTestSiteBadge && isTestSite;

  const { t } = useTranslation(["units", "crawlsList"]);
  const date = props.params.value
    ? new Date(props.params.value.toString())
    : null;
  const formattedDate = date
    ? dateFormatter(date, { dateStyle: "medium", timeStyle: "medium" })
    : null;
  return (
    <div className={classes.outerDiv}>
      <Typography className={classes.text} data-testid={props["data-testid"]}>
        {formattedDate}
        <br />
        {t("units:timeAgo", { date })}
      </Typography>
      <div className={classes.badge}>
        {showBadge && (
          <Chip color="primary" label={t("crawlsList:tableColumns.testSite")} />
        )}
      </div>
    </div>
  );
}
