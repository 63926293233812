import { Snackbar, SnackbarProps } from "@lumar/shared";
import {
  MenuItem,
  ListItemIcon,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { RefreshOutlined } from "@material-ui/icons";
import { useSnackbar, SnackbarKey } from "notistack";
import { useState } from "react";
import { camelCase, isEqual } from "lodash";
import {
  ReportDownload,
  ReportDownloadOutputType,
  useExportRowsMutation,
} from "../../../../../../graphql";
import { GenerationOption } from "../generationOptions";
import { ReportRowsFilter } from "../../../../../../_common/connection-filtering/types";
import { ReportInput } from "../../../../../Report.types";

const useStyles = makeStyles(() => ({
  icon: { fontSize: 23 },
  itemIcon: { minWidth: 35 },
}));

export type GeneratedReport = Pick<
  ReportDownload,
  "id" | "outputType" | "selectedMetrics" | "filter" | "fileURL"
>;

export function ExportedRowsGenerationItem({
  reportInput,
  option,
  generatedReports,
  selectedMetrics,
  autoFocus,
  index,
  filter,
}: {
  reportInput: ReportInput;
  option: GenerationOption;
  generatedReports?: GeneratedReport[];
  selectedMetrics: string[] | undefined;
  autoFocus?: boolean;
  index?: number;
  filter?: ReportRowsFilter;
}): JSX.Element {
  const classes = useStyles();
  const [isExporting, setIsExporting] = useState(false);

  const [generate] = useExportRowsMutation({
    refetchQueries: ["ExportedRows"],
    awaitRefetchQueries: true,
  });

  const generatedReport = findDownload(
    generatedReports,
    option.type,
    option.fromSelection ? selectedMetrics : undefined,
    filter,
  );

  const { enqueueSnackbar } = useSnackbar();
  const showSnack = (
    t?: string,
    variant?: SnackbarProps["variant"],
  ): SnackbarKey => enqueueSnackbar(<Snackbar variant={variant} title={t} />);

  async function handleGenerate(): Promise<void> {
    try {
      setIsExporting(true);
      await generate({
        variables: {
          input: {
            crawlId: reportInput.crawlId,
            reportTemplateCode: reportInput.reportTemplateCode,
            reportTypeCode: reportInput.reportTypeCode,
            segmentId: reportInput.segmentId,
            outputType: option.type,
            ...(option.fromSelection && { selectedMetrics }),
            filter,
          },
          reportDownloadId: generatedReport?.id ?? "0",
          regenerate: Boolean(generatedReport),
        },
      });
    } catch (e) {
      showSnack((e as Error).message, "error");
    } finally {
      setIsExporting(false);
    }
  }

  return (
    <MenuItem
      key={option.type}
      onClick={async () => await handleGenerate()}
      data-testid={`export-generate-${option.type.toLowerCase()}`}
      autoFocus={index === 0 ? autoFocus : false}
    >
      <ListItemIcon className={classes.itemIcon}>
        {isExporting ? (
          <CircularProgress
            color="primary"
            size="15px"
            style={{ marginLeft: 5 }}
          />
        ) : (
          <RefreshOutlined className={classes.icon}></RefreshOutlined>
        )}
      </ListItemIcon>
      {!generatedReport ? option.label : option.regenerateLabel}
    </MenuItem>
  );
}

function findDownload(
  downloads: GeneratedReport[] | undefined,
  type: ReportDownloadOutputType,
  metrics: string[] | undefined,
  filter?: ReportRowsFilter,
): GeneratedReport | undefined {
  return downloads?.find(
    (x) =>
      x.outputType === type &&
      isEqual(JSON.parse(x.filter ?? "{}"), filter ?? {}) &&
      isEqual(
        x.selectedMetrics?.map((e) => {
          if (e.startsWith("custom_metrics."))
            return e.replace("custom_metrics.", "customMetrics.");
          return camelCase(e);
        }),
        metrics,
      ),
  );
}
