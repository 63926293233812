import { Alert, BlueDataGrid, useSession, useTranslation } from "@lumar/shared";
import { useURLSearchParams } from "../../_common/routing/useURLSearchParams";
import {
  CrawlOverviewContextProvider,
  useCrawlOverviewContext,
} from "../../crawl-overview/CrawlOverviewContext";
import { Redirect, useParams } from "react-router-dom";
import { TopNavigation } from "../../_common/top-navigation/TopNavigation";
import { Box, CircularProgress } from "@material-ui/core";
import { ReportSearch } from "../../crawl-overview/ReportSearch";
import { SegmentSelect } from "../../segment-selector/SegmentSelect";
import { CrawlSelector } from "../../crawl-selector/CrawlSelector";
import { Routes } from "../../_common/routing/routes";
import { OverviewType } from "../../crawl-overview/types";
import { CrawlSettingsComparison } from "../../report/report-rows/report-grid/rows/crawl-settings-comparison/CrawlSettingsComparison";
import { CustomReportsTabs } from "./CustomReportsTabs";
import { ReportEntity } from "../../report/Report.types";
import {
  getReportParentCategory,
  ReportsTable,
} from "../../crawl-overview/reports-table/ReportsTable";
import { CustomReportOverviewType } from "../_common/CustomReportTypes";
import {
  CrawlContextCrawlReport,
  CrawlReportCategoryListNode,
  useCrawlContextData,
  useCrawlContextHelpers,
} from "../../crawl-overview/CrawlContext";
import { SearchWithDebounce } from "../../_common/searchWithDebounce/SearchWithDebounce";
import React from "react";
import { ReportsColumnBuilderProps } from "../../crawl-overview/reports-table/reportsTableDefaultColumns";
import { GridColDef } from "@mui/x-data-grid-pro";
import { CategoryIconCell } from "../../crawl-overview/reports-table/cells/CategoryIconCell";
import { ReportNameCell } from "../../crawl-overview/reports-table/cells/ReportNameCell";

export function CustomReportsOverview(): JSX.Element {
  const { hasFeatureFlagEnabled } = useSession();

  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (!hasFeatureFlagEnabled("custom-reports")) {
    return (
      <Redirect
        to={Routes.CrawlOverview.getUrl({
          accountId,
          projectId,
          crawlId,
          type: "dashboard",
        })}
      />
    );
  }
  return (
    <CrawlOverviewContextProvider reportEntity={ReportEntity.CustomReport}>
      <CustomReports />
    </CrawlOverviewContextProvider>
  );
}

function CustomReports(): JSX.Element {
  const searchParams = useURLSearchParams();
  const selectedType = searchParams.get("type");
  const { loading, data, helpers, crawlContext, errors } =
    useCrawlOverviewContext();

  const { t } = useTranslation("crawlOverview");
  const { t: tCustomReports } = useTranslation("customReports");
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (loading) {
    return <CircularProgress style={{ marginTop: 15 }} />;
  }

  if (errors) {
    return (
      <>
        <TopNavigation title={tCustomReports("pageTitle")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{t("genericError")}</Alert>
        </div>
      </>
    );
  }

  if (!data || !crawlContext.data || !helpers || !crawlContext.helpers) {
    return (
      <>
        <TopNavigation title={t("pageTitleDefault")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{t("error.noData")}</Alert>
        </div>
      </>
    );
  }

  return (
    <>
      <TopNavigation
        title={tCustomReports("pageTitle")}
        actions={[
          <ReportSearch key="report-search" />,
          <SegmentSelect key="segment-select" />,
          <CrawlSelector
            key="crawl-select"
            getPath={(crawlId) => {
              return Routes.CustomReports.getUrl({
                accountId,
                projectId,
                crawlId,
                type: OverviewType.All,
                segmentId: crawlContext.data?.selectedCrawlSegment?.segment.id,
              });
            }}
          />,
          <CrawlSettingsComparison key="crawl-settings-comparison" />,
        ]}
        capitalizeTitle
        availableForShare
      />
      <Box marginTop={3}>
        <CustomReportsTabs
          selectedType={selectedType ?? CustomReportOverviewType.All}
          onTypeChange={(type) => {
            if (type === selectedType) return;
            searchParams.delete("sorting");
            searchParams.set("type", type);
            searchParams.apply();
          }}
        />
        <Box marginTop="10px">
          {selectedType === CustomReportOverviewType.Previews ? (
            <CustomReportTemplateTable />
          ) : (
            <ReportsTable
              reportEntity={ReportEntity.CustomReport}
              key={selectedType}
              dataGridProps={{
                noRowsOverlay: {
                  title: tCustomReports("emptyStateTitle"),
                  detail: tCustomReports("emptyStateDetail"),
                },
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

function CustomReportTemplateTable(): JSX.Element {
  const { t } = useTranslation("customReports");
  const { t: tCrawlOverview } = useTranslation("crawlOverview");
  const { t: tReport } = useTranslation("report");
  const helpers = useCrawlContextHelpers();
  const { crawlReportCategoriesList } = useCrawlContextData();
  const [searchTerm, setSearchTerm] = React.useState("");
  const customReportTemplates =
    helpers.getProjectCustomReportTemplatesPendingGenerationList(searchTerm);

  return (
    <BlueDataGrid
      name="custom-report-templates-table"
      columns={[
        getCategoryIconColummn({ t: tCrawlOverview, tReport }),
        getNameColumn({ t: tCrawlOverview, tReport }),
      ]}
      rows={customReportTemplates.map((r) => {
        return {
          ...r,
          parentCategory: getReportParentCategory(crawlReportCategoriesList, r),
        };
      })}
      components={{
        ToolbarLeft: [SearchWithDebounce],
      }}
      componentsProps={{
        toolbar: {
          toolbarLeft: {
            debounceInMs: 200,
            placeholder: "Search custom report previews",
            value: searchTerm,
            valueChangeCallbackFn: setSearchTerm,
          },
        },
        noRowsOverlay: {
          title: t("emptyStateTitle"),
          detail: t("emptyStateDetail"),
        },
      }}
    />
  );
}

function getCategoryIconColummn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "categoryIcon",
    headerName: t("table.header.categoryIcon"),
    description: t("table.tooltips.categoryIcon"),
    width: 120,
    align: "center",
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.parentCategory?.name,
    renderCell: ({ row }) => (
      <CategoryIconCell
        category={row.parentCategory as CrawlReportCategoryListNode | undefined}
      />
    ),
  };
}

function getNameColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "name",
    headerName: t("table.header.name"),
    description: t("table.tooltips.name"),
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.reportTemplate.name,
    renderCell: ({ row }) => (
      <ReportNameCell report={row as CrawlContextCrawlReport} />
    ),
  };
}
