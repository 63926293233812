import React from "react";
import {
  useUrlServerPagination,
  getRawCrawlId,
  getRawProjectId,
  useSession,
  TimeZoneContext,
} from "@lumar/shared";
import { useParams } from "react-router-dom";

import {
  ModuleCode,
  OrderDirection,
  ProjectEdge,
  ProjectOrderField,
} from "../../graphql";
import { formatFilters } from "../projects-filter/formatFilters";
import { isCrawlInProgress } from "../projects-filter/helpers";
import { AccountProjectsVariant } from "../../_common/routing/routes";
import { useAccountProjectsMutations } from "../data/useAccountProjectsMutations";
import { useAccountProjectsQuery } from "./useAccountProjectsQuery";
import { AccountProjectsData, Project } from "./types";
import { GridColumns, GridSortItem } from "@mui/x-data-grid-pro";
import { FilterRuleFormValue } from "../../_common/connection-filtering/types";
import { useAccountProjectsFilterParam } from "../useAccountProjectsFilterParam";
import { insertIf } from "../../_common/insertIf";

const ACCOUNT_PROJECTS_DEFAULT_PAGE_SIZE = 20;

export function useAccountProjectsData({
  baseFilter,
  variant,
  columns,
  defaultSort,
  projectCountModules,
}: {
  baseFilter: FilterRuleFormValue | undefined;
  variant: AccountProjectsVariant;
  columns: GridColumns;
  defaultSort?: { field: ProjectOrderField; order: OrderDirection };
  projectCountModules: (ModuleCode | "All")[];
}): AccountProjectsData {
  const { accountId } = useParams<{ accountId: string }>();

  const { account } = useSession();
  const { timeZone } = React.useContext(TimeZoneContext);

  const { pageInfo, ...pagination } = useUrlServerPagination(
    ACCOUNT_PROJECTS_DEFAULT_PAGE_SIZE,
    defaultSort?.field || ProjectOrderField.FinishedAt,
    defaultSort?.order || OrderDirection.Desc,
  );

  const { filter } = useAccountProjectsFilterParam();

  const filerOptions = {
    metadata: {
      currentBillingPeriod: account.subscription.currentBillingPeriod,
    },
    timeZoneOffset: timeZone.offset,
  };

  const convertedBaseFilter = baseFilter
    ? formatFilters({
        filters: [baseFilter],
        ...filerOptions,
      })
    : undefined;

  const convertedFilter = formatFilters({
    filters: [
      ...(filter.filters ?? []),
      ...insertIf(filter.search, {
        id: "",
        metricCode: "nameOrDomain",
        predicateKey: "contains",
        predicateValue: filter.search || "",
      }),
    ],
    ...filerOptions,
  });

  const statusFilter = formatFilters({
    filters: [
      ...insertIf(filter.scheduled, {
        id: "",
        metricCode: "schedulesTotalCount",
        predicateKey: "eq",
        predicateValue: true,
      }),
      ...insertIf(filter.draft, {
        id: "",
        metricCode: "crawlsTotalCount",
        predicateKey: "lt",
        predicateValue: 1,
      }),
      ...insertIf(filter.active, {
        id: "",
        metricCode: "active",
        predicateKey: "eq",
        predicateValue: true,
      }),
    ],
    ...filerOptions,
  });

  const { data, loading, error, startPolling, stopPolling } =
    useAccountProjectsQuery({
      accountId,
      filter: {
        _and: [
          ...(convertedBaseFilter?.filter?._and || []),
          ...(convertedFilter?.filter?._and || []),
          { _or: [...(statusFilter.filter?._and || [])] },
        ],
      },
      baseFilter: { _and: convertedBaseFilter?.filter?._and || [] },
      crawlTypeCodes: convertedFilter?.crawlTypeCodes,
      crawlTypeCodesExcluded: convertedFilter?.crawlTypeCodesExcluded,
      pagination: pageInfo,
      variant,
      columns,
      projectCountModules,
    });

  const hasCrawlInProgress = Boolean(
    data?.getAccount?.projects?.edges.find((p) =>
      isCrawlInProgress(p.node.lastCrawlStatus),
    ),
  );

  React.useEffect(() => {
    if (hasCrawlInProgress) {
      if (variant === "running") {
        startPolling(5000);
      } else {
        startPolling(60000);
      }
    }
    return () => stopPolling();
  }, [hasCrawlInProgress, startPolling, stopPolling, variant]);

  const projectCountModulesCache = React.useRef<
    AccountProjectsData["projectTotalCountModule"]
  >({});

  const projectData = React.useMemo<
    Pick<
      AccountProjectsData,
      | "projects"
      | "projectCount"
      | "projectTotalCount"
      | "projectTotalCountModule"
      | "crawlTypesMetadata"
    >
  >(() => {
    // Note: Reusing the previous data, because the project count per module
    // should not change when the selected tab changes.
    if (data) {
      projectCountModulesCache.current = Object.fromEntries([
        ["All", data.getAccount?.projectsAll?.totalCount],
        ...Object.values(ModuleCode).map((moduleCode) => [
          moduleCode,
          data.getAccount?.[`projects${moduleCode}`]?.totalCount,
        ]),
      ]);
    }

    return {
      projects: data?.getAccount?.projects.edges.map(formatProject) || [],
      projectCount: data?.getAccount?.projects.totalCount ?? 0,
      projectTotalCount: data?.getAccount?.allProjects?.totalCount ?? 0,
      projectTotalCountModule: projectCountModulesCache.current,
      crawlTypesMetadata: data?.getCrawlTypesMetadata || [],
    };
  }, [data]);

  const mutations = useAccountProjectsMutations();

  return {
    ...projectData,
    loading,
    error,
    pagination: {
      ...pagination,
      paginationState: {
        ...pagination.paginationState,
        sortModel:
          pagination.paginationState.sortModel ??
          pageInfo.orderBy?.map(getSortModel),
      },
      pageSize:
        pagination.paginationState.pageSize ??
        ACCOUNT_PROJECTS_DEFAULT_PAGE_SIZE,
      paginationMode: "server",
    },
    ...mutations,
  };
}

function formatProject(edge: ProjectEdge): Project {
  const lastCrawl = edge.node.crawls?.edges?.[0]?.node;
  return {
    id: getRawProjectId(edge.node.id),
    isTestSuite: edge.node.isTestSuite,
    name: edge.node.name,
    primaryDomain: edge.node.primaryDomain,
    crawlTypes: edge.node.crawlTypes,
    createdAt: edge.node.createdAt ? new Date(edge.node.createdAt) : undefined,
    lastCrawlId: lastCrawl ? getRawCrawlId(lastCrawl.id) : undefined,
    lastCrawlStatus: edge.node.lastCrawlStatus ?? undefined,
    lastCrawlFinishedAt: lastCrawl?.finishedAt
      ? new Date(lastCrawl.finishedAt)
      : undefined,
    lastCrawlCreatedAt: lastCrawl?.createdAt
      ? new Date(lastCrawl.createdAt)
      : undefined,
    crawlSpeed: lastCrawl?.crawledPerSecond ?? 0,
    totalSteps: lastCrawl?.totalSteps ?? 0,
    runningTotalSteps: lastCrawl?.crawlStat?.crawlLevels?.reduce(
      (acc, curr) => curr.stepsProcessed + acc,
      0,
    ),
    lastFinishedCrawlId: edge.node.lastFinishedCrawl
      ? getRawCrawlId(edge.node.lastFinishedCrawl.id)
      : undefined,
    finishedAt: edge.node.finishedAt
      ? new Date(edge.node.finishedAt)
      : undefined,
    segmentsTotalCount: edge.node.segmentsTotalCount ?? 0,
    taskTotalCount: edge.node.legacyTasks?.totalCount ?? 0,
    // Note: Crawls prior to GA-1133 do not have a crawlUrlsTotal value.
    crawlUrls: edge.node.crawlUrlsTotal ?? edge.node.allPagesTotal ?? undefined,
    crawlsTotalCount: edge.node.crawlsTotalCount ?? 0,
    schedule: edge.node.schedule
      ? {
          id: edge.node.schedule.id,
          scheduleFrequency: edge.node.schedule.scheduleFrequency,
          nextRunTime: new Date(edge.node.schedule.nextRunTime),
        }
      : undefined,
    moduleCode: edge.node.moduleCode,
  };
}

function getSortModel(order: {
  field: string;
  direction: OrderDirection;
}): GridSortItem {
  return {
    field: order.field,
    sort: order.direction === OrderDirection.Asc ? "asc" : "desc",
  };
}
