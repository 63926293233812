import {
  AccessibilityCircle,
  GlobeAltSolid,
  SiteSpeedV2Icon,
  useTranslation,
} from "@lumar/shared";
import { ModuleCode } from "../../graphql";
import { ProjectsGridRenderCellParams } from "../data/types";
import { Tooltip, useTheme } from "@material-ui/core";

export function ProjectTypeCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element | null {
  const theme = useTheme();
  const { t } = useTranslation("projectsList");

  const [Icon, title] = (() => {
    switch (row.moduleCode) {
      case ModuleCode.Seo:
        return [GlobeAltSolid, t("modules.seo")];
      case ModuleCode.Accessibility:
        return [AccessibilityCircle, t("modules.accessibility")];
      case ModuleCode.SiteSpeed:
        return [SiteSpeedV2Icon, t("modules.siteSpeed")];
      default:
        return [undefined, ""];
    }
  })();

  if (!Icon) return null;

  return (
    <Tooltip title={title}>
      <div style={{ padding: theme.spacing(1), lineHeight: "normal" }}>
        <Icon
          style={{
            color: theme.palette.grey[700],
            fontSize: theme.typography.pxToRem(20),
          }}
        />
      </div>
    </Tooltip>
  );
}
