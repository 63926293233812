import {
  Button,
  Close,
  EditSolid,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTranslation,
  useUrlServerPagination,
  XSolid,
} from "@lumar/shared";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { GridApiRef } from "@mui/x-data-grid-pro";
import { useFormik } from "formik";

import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useUpdateCustomReportTemplateMutation } from "../../graphql";
import {
  getMetricsGroupingsFromGrid,
  mapFloatToTotalWeight,
  mapIntToTotalSign,
  mapTotalSignToInt,
  mapTotalWeightToFloat,
} from "../_common/CustomReportHelpers";
import {
  CustomReportTemplateDTO,
  UpdateCustomReportTemplateFormValues,
} from "../_common/CustomReportTypes";
import { updateCustomReportTemplateSchema } from "../_common/CustomReportValidation";
import { ReportGridColumn } from "../../report/report-rows/report-grid/columns/DefaultReportGridColumns.types";

interface UpdateCustomReportDialogProps {
  isLoading?: boolean;
  disabled?: boolean;
  customReportTemplate: CustomReportTemplateDTO;
  reportTemplateQueryVersion: number;
  reportQueryVersion: number;
  gridApiRef: GridApiRef;
  cardMetrics: ReportGridColumn[];
  foundInSources: ReportGridColumn[];
  isPendingReportGeneration: boolean;
}

export const UpdateCustomReportDialog = (
  props: UpdateCustomReportDialogProps,
): JSX.Element => {
  const disabled = props.disabled ?? false;
  const { t } = useTranslation("customReports");
  const { pageInfo } = useUrlServerPagination(0);
  const classes = useStyles();
  const [createOpen, setCreateOpen] = useState(false);

  const [updateCustomReportMutation] = useUpdateCustomReportTemplateMutation({
    onCompleted: () => {
      const title = props.isPendingReportGeneration
        ? t("customReportTemplateUpdatedPreview")
        : t("customReportTemplateUpdated");
      enqueueSnackbar(<Snackbar title={title} variant="success" />);
      closeDialogAndReset();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar title={error.message} variant="error" />);
    },
    refetchQueries: ["CustomReport", "CrawlContextCustomReports"],
  });

  const initialValues: UpdateCustomReportTemplateFormValues = {
    customReportTemplateId: props.customReportTemplate.id,
    name: props.customReportTemplate.name,
    description: props.customReportTemplate.description ?? undefined,
    totalSign: mapIntToTotalSign(props.customReportTemplate.totalSign),
    totalWeight: mapFloatToTotalWeight(props.customReportTemplate.totalWeight),
    metricsGroupings: getMetricsGroupingsFromGrid({
      cardMetrics: props.cardMetrics,
      gridApiRef: props.gridApiRef,
    }),
    orderBy: pageInfo.orderBy ?? undefined,
  };

  const formik = useFormik<UpdateCustomReportTemplateFormValues>({
    enableReinitialize: true,
    initialValues,
    validationSchema: updateCustomReportTemplateSchema,
    onSubmit: async (values) => {
      await updateCustomReportMutation({
        variables: {
          input: {
            customReportTemplateId: values.customReportTemplateId,
            name: values.name,
            description: values.description,
            totalSign: mapTotalSignToInt(values.totalSign),
            totalWeight: mapTotalWeightToFloat(values.totalWeight),
            metricsGroupings: getMetricsGroupingsFromGrid({
              cardMetrics: props.cardMetrics,
              gridApiRef: props.gridApiRef,
            }),
            orderBy: values.orderBy,
          },
        },
      });
    },
  });

  const closeDialogAndReset = (): void => {
    setCreateOpen(false);
    formik.resetForm();
    formik.validateForm(initialValues);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<EditSolid />}
        onClick={() => setCreateOpen(true)}
        disabled={disabled || props.isLoading}
      >
        {t("updateCustomReport")}
      </Button>
      <Dialog
        open={createOpen}
        onClose={() => closeDialogAndReset()}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h2">{t("updateCustomReport")}</Typography>
          <IconButton onClick={() => closeDialogAndReset()}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <form
            id="createCustomReportTemplateForm"
            onSubmit={formik.handleSubmit}
          >
            <div className={classes.formLine}>
              <TextField
                label="Report name*"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className={classes.formLine}>
              <TextField
                label="Report description"
                multiline={true}
                minRows={4}
                maxRows={4}
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </div>
            <div className={classes.formTwoColumns}>
              <Select
                label="Impact"
                id="totalSign"
                name="totalSign"
                value={formik.values.totalSign}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="negative">
                  {t("templateSign.negative")}
                </MenuItem>
                <MenuItem value="neutral">{t("templateSign.neutral")}</MenuItem>
                <MenuItem value="positive">
                  {t("templateSign.positive")}
                </MenuItem>
              </Select>
              <Select
                label="Priority"
                id="totalWeight"
                name="totalWeight"
                value={formik.values.totalWeight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="none"> {t("templateWeight.none")}</MenuItem>
                <MenuItem value="low">{t("templateWeight.low")}</MenuItem>
                <MenuItem value="medium">{t("templateWeight.medium")}</MenuItem>
                <MenuItem value="high">{t("templateWeight.high")}</MenuItem>
                <MenuItem value="critical">
                  {t("templateWeight.critical")}
                </MenuItem>
              </Select>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => closeDialogAndReset()}
            startIcon={<XSolid />}
          >
            {t("cancel")}
          </Button>
          <Button
            loading={formik.isSubmitting}
            disabled={!formik.isValid || formik.isSubmitting}
            type="submit"
            form="createCustomReportTemplateForm"
            variant="contained"
            color="primary"
            startIcon={<EditSolid />}
          >
            {t("updateCustomReport")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formLine: {
    marginBottom: theme.spacing(2),
  },
  formTwoColumns: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      width: "calc(50% - 4px)",
    },
  },
  dialog: {
    width: "100%",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1, 3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
}));
