import React, { useMemo, useState } from "react";
import {
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Typography,
  TreeSolid,
  useNumberFormatter,
} from "@lumar/shared";
import { FixedSizeList } from "react-window";

import { ManualSitemaps } from "./ManualSitemaps";
import { ManualUploads } from "../manual-uploads/ManualUploads";
import { FormSubmitContext } from "../../components/FormSubmitContext";
import { CustomAccordion, CustomAccordionBadge } from "../CustomAccordion";
import { CrawlType } from "../../../../graphql";
import { useSitemaps } from "./useSitemaps";
import { SearchField } from "../../components/SearchField";
import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { FormValues } from "../data/types";
import { DataOnlySourceSwitch } from "../DataOnlySourceSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(1),
  },
  root: {
    width: "100%",
  },
  searchInput: {
    width: "100%",
  },
  alignRightGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  sitemaps: {
    display: "flex",
    flexDirection: "column",
    height: "250px",
    overflow: "auto",
    marginTop: theme.spacing(0.75),
  },
  url: {
    fontWeight: 400,
  },
  malformed: {
    color: theme.palette.error.main,
  },
  chipSmall: {
    pointerEvents: "none",
    height: 20,
    color: "white",
    background: theme.palette.grey[600],
  },
  rowBox: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  rowBoxRight: {
    display: "flex",
    marginLeft: "auto",
  },
}));

export const Sitemaps = React.memo(function Sitemaps({
  initialState,
}: {
  initialState: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");
  const numberFormatter = useNumberFormatter();

  const { sitemaps, setStatus, addSitemap } = useSitemaps();

  const [searchString, setSearchString] = useState("");

  const hasSitemaps = sitemaps.length > 0;
  const isFiltered = Boolean(searchString);

  const formattedSearchString = searchString.toLowerCase();
  const filteredSitemaps = sitemaps.filter((sitemap) =>
    sitemap.url.toLowerCase().includes(formattedSearchString),
  );

  const initialSitemaps = useMemo(() => {
    return filteredSitemaps
      .filter((sitemap) => sitemap.enabled)
      .map((sitemap) => sitemap.urlDigest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSitemaps]);

  const allSelected =
    !!filteredSitemaps.length && !filteredSitemaps.find((s) => !s.enabled);

  function setAllStatus(status: boolean): void {
    const setSatusForAll = filteredSitemaps.length === sitemaps.length;

    if (setSatusForAll) setStatus("all", status);
    setStatus(filteredSitemaps, status);
  }

  const discoverInRobotsCheckbox = (
    <FastField
      name="sitemaps.discoverSitemapsInRobotsTxt"
      component={CheckboxWithLabel}
      type="checkbox"
      Label={{ label: t("sources.sitemaps.useRobots") }}
      data-testid="crawl-settings-discover-crawl-robots"
    />
  );

  const addManualSiteMaps = (
    <FormSubmitContext>
      {({ isSubmitting }) => (
        <ManualSitemaps onAddSitemap={addSitemap} disabled={isSubmitting} />
      )}
    </FormSubmitContext>
  );

  const getBadges = (values: FormValues): CustomAccordionBadge[] => {
    if (values.dataOnlyCrawlType.sitemaps) {
      return [
        {
          label: t("sources.dataOnly"),
          info: "",
          color: "primary" as const,
        },
      ];
    }
    return [];
  };

  const listSitemaps = [
    ...filteredSitemaps.filter((sitemap) =>
      initialSitemaps.includes(sitemap.urlDigest),
    ),
    ...filteredSitemaps.filter(
      (sitemap) => !initialSitemaps.includes(sitemap.urlDigest),
    ),
  ];

  return (
    <CustomAccordion
      title={t("sources.sitemaps.title")}
      icon={<TreeSolid fontSize="small" style={{ display: "block" }} />}
      tooltip={t("sources.sitemaps.tooltip")}
      getBadges={getBadges}
      initialState={initialState}
      fieldName="crawlType.sitemaps"
      bottomContent={<DataOnlySourceSwitch name="dataOnlyCrawlType.sitemaps" />}
      data-pendo="sitemaps-header"
      data-testid="sitemaps-section"
    >
      <div className={classes.root}>
        <Grid container spacing={1} alignItems="center">
          {hasSitemaps ? (
            <>
              <Grid item xs={12} md={4}>
                <SearchField
                  value={searchString}
                  onChange={(value) => setSearchString(value)}
                  placeholder={t("sources.sitemaps.findSitemaps")}
                  className={classes.searchInput}
                  data-testid="crawl-settings-sitemaps-filter"
                />
              </Grid>
              <Grid item xs={12} md={8} className={classes.alignRightGrid}>
                {addManualSiteMaps}
              </Grid>
              <Grid item xs={12}>
                <FormSubmitContext>
                  {({ isSubmitting }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allSelected}
                            onChange={(_, enabled) => setAllStatus(enabled)}
                            disabled={isSubmitting || !filteredSitemaps.length}
                            data-testid="crawl-settings-sitemaps-select-deselect-all"
                          />
                        }
                        label={
                          isFiltered
                            ? t("sources.sitemaps.selectDeselectFiltered")
                            : t("sources.sitemaps.selectDeselectAll")
                        }
                        style={{ marginBottom: "8px", marginTop: "8px" }}
                      />
                      <Divider />
                      <div className={classes.sitemaps}>
                        <FixedSizeList
                          width="100%"
                          height={250}
                          itemSize={20}
                          itemCount={listSitemaps.length}
                          itemData={{
                            sitemaps: listSitemaps,
                          }}
                        >
                          {({ index, data, style }) => {
                            const sitemap = data.sitemaps[index];
                            return (
                              <FormControlLabel
                                key={sitemap.url}
                                style={style}
                                control={
                                  <Checkbox
                                    checked={sitemap.enabled}
                                    onChange={(_, enabled) =>
                                      setStatus(sitemap, enabled)
                                    }
                                    disabled={isSubmitting}
                                    data-testid="crawl-settings-sitemaps"
                                  />
                                }
                                label={
                                  <div
                                    style={{ whiteSpace: "nowrap" }}
                                    className={
                                      sitemap.malformed
                                        ? classes.malformed
                                        : classes.url
                                    }
                                  >
                                    {sitemap.url}
                                  </div>
                                }
                              />
                            );
                          }}
                        </FixedSizeList>
                      </div>
                    </>
                  )}
                </FormSubmitContext>
                <Divider />
                <div className={classes.rowBox}>
                  {discoverInRobotsCheckbox}
                  <div className={classes.rowBoxRight}>
                    <Typography variant="caption">
                      {t("sources.sitemaps.totalSitemaps")}{" "}
                      {numberFormatter(sitemaps.length)}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={8}>
                <Typography variant="subtitle2Medium">
                  {t("sources.sitemaps.robotsLabel")}
                </Typography>
                {discoverInRobotsCheckbox}
              </Grid>
              <Grid item xs={12} md={4} className={classes.alignRightGrid}>
                {addManualSiteMaps}
              </Grid>
            </>
          )}
        </Grid>
        <Typography variant="subtitle2Medium" style={{ marginTop: "15px" }}>
          {t("sources.sitemaps.uploadLabel")}
        </Typography>
        <Typography variant="caption">
          {t("sources.sitemaps.uploadDescription")}
        </Typography>
        <FormSubmitContext>
          {({ isSubmitting }) => (
            <ManualUploads
              crawlType={CrawlType.Sitemap}
              disabled={isSubmitting}
              disableEdit
              acceptedFiles={[".txt", ".txt.gz", ".xml", ".xml.gz"]}
            />
          )}
        </FormSubmitContext>
      </div>
    </CustomAccordion>
  );
});
