import DateFnsUtils from "@date-io/date-fns";
import {
  AppLayout,
  AuthPage,
  AuthProvider,
  ChurnZeroProvider,
  getRawAccountId,
  GQLClientProvider,
  InactiveAccountRedirect,
  SessionProvider,
  SnackbarProvider,
  useDocumentTitle,
  useTranslation,
} from "@lumar/shared";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Translation } from "react-i18next";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AppTimeZoneProvider } from "./_common/locale/AppTimeZoneProvider";
import { Routes } from "./_common/routing/routes";
import { TagsController } from "./_common/tags-controller/TagsController";
import { AccountProjects } from "./account-projects/AccountProjects";
import { CrawlContextProvider } from "./crawl-overview/CrawlContext";
import { CrawlOverviewPage } from "./crawl-overview/CrawlOverview";
import { CustomReportsOverview } from "./custom-report/custom-report-overview/CustomReportsOverview";
import { CustomReportContainer } from "./custom-report/CustomReportContainer";
import { DataExplorer } from "./data-explorer/DataExplorer";
import { NotFound } from "./not-found/NotFound";
import { ReportAdjustmentPage } from "./project-crawls/crawl-settings/report-adjustment/ReportAdjustmentPage";
import { ProjectCrawls } from "./project-crawls/ProjectCrawls";
import { Report } from "./report/Report";
import { ResourceDetail } from "./resource-detail/ResourceDetail";
import { ResourceDetailSidebarContent } from "./resource-detail/ResourceDetailSidebarContent";
import { Segments } from "./segment-manager/Segments";
import { CrawlSidebarContent } from "./sidebar/CrawlSidebarContent";
import { AccountTaskManager } from "./task-manager/AccountTaskManager";
import { ProjectTaskManager } from "./task-manager/ProjectTaskManager";

export const App = (): JSX.Element => {
  const { t } = useTranslation("navigation");

  useDocumentTitle(t("title"));

  return (
    <BrowserRouter>
      <Translation ns="errors">
        {(t) => (
          <AuthProvider app="analyze">
            <GQLClientProvider
              name="analyze"
              version={process.env.REACT_APP_BUILD_HASH}
            >
              <SessionProvider
                onSessionError={{
                  message: t("sessionError"),
                  actions: [
                    {
                      name: t("reload"),
                      onClick: () => location.reload(),
                    },
                  ],
                }}
              >
                <ChurnZeroProvider>
                  <InactiveAccountRedirect>
                    <SnackbarProvider>
                      <AppTimeZoneProvider>
                        <TagsController />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Switch>
                            <Redirect
                              exact
                              from="//*"
                              to={{
                                pathname: "/*",
                                search: window.location.search,
                              }}
                            />
                            <Route exact path="/">
                              <Redirect to={Routes.Login.ROUTE} />
                            </Route>
                            <Route exact path={Routes.Login.ROUTE}>
                              <AuthPage
                                onSession={(session, history) => {
                                  if (session) {
                                    const accountId = getRawAccountId(
                                      session.account.id,
                                    );
                                    history.replace(
                                      Routes.Projects.getUrl({ accountId }),
                                    );
                                  }
                                }}
                              />
                            </Route>
                            {/* Crawl-related routes */}
                            <Route exact path={Routes.CrawlOverview.ROUTE}>
                              <CrawlContextProvider>
                                <AppLayout
                                  app="analyze"
                                  sidebarContentComponent={CrawlSidebarContent}
                                >
                                  <CrawlOverviewPage />
                                </AppLayout>
                              </CrawlContextProvider>
                            </Route>
                            <Route exact path={Routes.ReportAdjustment.ROUTE}>
                              <AppLayout app="analyze">
                                <ReportAdjustmentPage />
                              </AppLayout>
                            </Route>
                            <Route exact path={Routes.Report.ROUTE}>
                              <CrawlContextProvider>
                                <AppLayout
                                  app="analyze"
                                  sidebarContentComponent={CrawlSidebarContent}
                                >
                                  <Report />
                                </AppLayout>
                              </CrawlContextProvider>
                            </Route>
                            <Route exact path={Routes.CustomReports.ROUTE}>
                              <CrawlContextProvider>
                                <AppLayout
                                  app="analyze"
                                  sidebarContentComponent={CrawlSidebarContent}
                                >
                                  <CustomReportsOverview />
                                </AppLayout>
                              </CrawlContextProvider>
                            </Route>
                            <Route exact path={Routes.CustomReport.ROUTE}>
                              <CrawlContextProvider>
                                <AppLayout
                                  app="analyze"
                                  sidebarContentComponent={CrawlSidebarContent}
                                >
                                  <CustomReportContainer />
                                </AppLayout>
                              </CrawlContextProvider>
                            </Route>
                            <Route exact path={Routes.DataExplorer.ROUTE}>
                              <CrawlContextProvider>
                                <AppLayout
                                  app="analyze"
                                  sidebarContentComponent={CrawlSidebarContent}
                                >
                                  <DataExplorer />
                                </AppLayout>
                              </CrawlContextProvider>
                            </Route>
                            <Route exact path={Routes.Tasks.ROUTE}>
                              <CrawlContextProvider>
                                <AppLayout
                                  app="analyze"
                                  sidebarContentComponent={CrawlSidebarContent}
                                >
                                  <ProjectTaskManager />
                                </AppLayout>
                              </CrawlContextProvider>
                            </Route>
                            <Route exact path={Routes.ResourceDetail.ROUTE}>
                              <CrawlContextProvider>
                                <AppLayout
                                  app="analyze"
                                  sidebarContentComponent={
                                    ResourceDetailSidebarContent
                                  }
                                >
                                  <ResourceDetail />
                                </AppLayout>
                              </CrawlContextProvider>
                            </Route>
                            <Route exact path={Routes.Projects.ROUTE}>
                              <AppLayout app="analyze">
                                <AccountProjects />
                              </AppLayout>
                            </Route>
                            <Route exact path={Routes.Crawls.ROUTE}>
                              <AppLayout app="analyze">
                                <ProjectCrawls />
                              </AppLayout>
                            </Route>
                            <Route exact path={Routes.NewProject.ROUTE}>
                              <AppLayout app="analyze">
                                <ProjectCrawls />
                              </AppLayout>
                            </Route>
                            <Route exact path={Routes.SegmentManager.ROUTE}>
                              <AppLayout app="analyze">
                                <Segments />
                              </AppLayout>
                            </Route>
                            <Route exact path={Routes.AccountTasks.ROUTE}>
                              <AppLayout app="analyze">
                                <AccountTaskManager />
                              </AppLayout>
                            </Route>
                            <Route component={() => <NotFound />} />
                          </Switch>
                        </MuiPickersUtilsProvider>
                      </AppTimeZoneProvider>
                    </SnackbarProvider>
                  </InactiveAccountRedirect>
                </ChurnZeroProvider>
              </SessionProvider>
            </GQLClientProvider>
          </AuthProvider>
        )}
      </Translation>
    </BrowserRouter>
  );
};
