import SvgIcon from "@material-ui/core/SvgIcon";
import { CodeSolid, Camera } from "@lumar/shared";
import { Metrics, ResourceDetailData } from "../data/types";
import { StoredHtml } from "./sections/StoredHtml";
import { StoredScreenshot } from "./sections/StoredScreenshot";
import { ResourceDetailAttachmentContainerName } from "./resourceDetailAttachmentContainerName";

interface AttachmentSection {
  code: string;
  name: string; // make it translation key
  icon: typeof SvgIcon;
  component: React.ElementType<{ data: ResourceDetailData }>;
}

type ContainerAttachmentSectionConfig = {
  [key in ResourceDetailAttachmentContainerName]: AttachmentSection;
};

const containerAttachmentSectionConfig: ContainerAttachmentSectionConfig = {
  HtmlStoring: {
    code: "storedHtml",
    name: "HTML",
    component: StoredHtml,
    icon: CodeSolid,
  },
  ScreenshotStoring: {
    code: "storedScreenshot",
    name: "Screenshot",
    component: StoredScreenshot,
    icon: Camera,
  },
};

export function useAttachmentSections(metrics: Metrics): AttachmentSection[] {
  const attachments: { name: string }[] = metrics["attachments"]?.value ?? [];
  const attachmentNames = attachments.map((a) => a.name);
  return Object.entries(containerAttachmentSectionConfig)
    .filter(([containerName]) =>
      attachmentNames.find((name) => name.startsWith(containerName + "/")),
    )
    .map(([, section]) => section);
}
