import { Alert, useTranslation } from "@lumar/shared";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSearchParam } from "../_common/routing/useSearchParam";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { useCrawlContext } from "../crawl-overview/CrawlContext";
import { useCustomReportQuery } from "../graphql";
import { CustomReportGrid } from "./CustomReportGrid";
import { mapStringToReportTypeCode } from "./_common/CustomReportHelpers";

export const CustomReport = (): JSX.Element => {
  const { t } = useTranslation("common");
  const { t: tCustomReports } = useTranslation("customReports");
  const searchParams = useURLSearchParams();
  const { accountId, projectId, crawlId, customReportTemplateId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
    customReportTemplateId: string;
  }>();
  const reportType = useSearchParam("reportType");

  const {
    data: crawlContextData,
    errors: crawlContextErrors,
    loading: crawlContextLoading,
    crawlNotFound,
  } = useCrawlContext();

  const { data, loading, error } = useCustomReportQuery({
    variables: {
      crawlId,
      customReportTemplateId,
      segmentId: crawlContextData?.selectedCrawlSegment?.segment.id,
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (!data?.customReport && !loading && !error) {
      searchParams.set("status", "pending");
      searchParams.apply();
    }
  }, [data, loading, error, searchParams]);

  if (crawlContextErrors) {
    return (
      <>
        <TopNavigation title={tCustomReports("pageTitle")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{t("genericError")}</Alert>
        </div>
      </>
    );
  }

  if (crawlNotFound) {
    return (
      <>
        <TopNavigation title={tCustomReports("pageTitle")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="warning">{t("crawlNotFound")}</Alert>
        </div>
      </>
    );
  }

  const customReport = data?.customReport;
  const reportAdviceCollapsed = data?.me?.reportAdviceCollapsed;

  if (loading || crawlContextLoading || !customReport) {
    return <CircularProgress style={{ marginTop: 15 }} />;
  }

  return (
    <CustomReportGrid
      crawlId={crawlId}
      accountId={accountId}
      projectId={projectId}
      segmentId={crawlContextData?.selectedCrawlSegment?.segment.id}
      lastFinishedCrawlId={
        crawlContextData?.crawlProject.lastFinishedCrawl?.rawID
      }
      customReportTemplate={customReport.customReportTemplate}
      baseReportTemplate={customReport.reportTemplate}
      reportTypeCode={mapStringToReportTypeCode(reportType ?? "basic")}
      reportAdviceCollapsed={Boolean(reportAdviceCollapsed)}
    />
  );
};
