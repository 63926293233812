import React from "react";
import { MenuItem, StandardTextFieldProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { FastField, FieldProps } from "formik";
import {
  Select,
  TextField,
  Typography,
  formToSelectProps,
  formToTextFieldProps,
} from "@lumar/shared";

import { FormValues } from "../data/types";
import { GoogleAnalytics4SourceType } from "../../../../graphql";
import { SourcesContext } from "../data/useSourcesFormValues";
import { Autocomplete } from "@material-ui/lab";
import { withDisplayValue } from "../../components/TextFieldInput";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F3F7",
    border: "1px solid #EBEFF3",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    height: "100%",
  },
  property: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(0.625),
  },
  input: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
}));

export function GoogleAnalytics4Inputs(): JSX.Element {
  return (
    <FastField
      name="analytics.googleAnalytics4"
      component={GoogleAnalytics4InputsInner}
    />
  );
}

export function GoogleAnalytics4InputsInner({
  form,
  field: { value },
}: FieldProps<FormValues["analytics"]["googleAnalytics4"]>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const { googleAnalytics4Properties } = React.useContext(SourcesContext);
  const property = googleAnalytics4Properties.find(
    (x) => x.propertyId === value.propertyId,
  );

  const disabled = !property;

  const lastDays = ["7", "14", "30", "60", "90", "180", "365"];

  const sourceOptions = [
    {
      value: GoogleAnalytics4SourceType.All,
      name: t("sources.analytics.googleAnalytics4.sourceAll"),
    },
    {
      value: GoogleAnalytics4SourceType.OrganicSearchLandingPages,
      name: t(
        "sources.analytics.googleAnalytics4.sourceOrganicSearchLandingPages",
      ),
    },
    {
      value: GoogleAnalytics4SourceType.GoogleOrganicSearchLandingPages,
      name: t(
        "sources.analytics.googleAnalytics4.sourceGoogleOrganicSearchLandingPages",
      ),
    },
    {
      value: GoogleAnalytics4SourceType.BingOrganicSearchLandingPages,
      name: t(
        "sources.analytics.googleAnalytics4.sourceBingOrganicSearchLandingPages",
      ),
    },
  ];

  const lastNDaysProps = formToTextFieldProps(
    form,
    "analytics.googleAnalytics4.lastNDays",
  );

  const minimumSessionsProps = formToTextFieldProps(
    form,
    "analytics.googleAnalytics4.minimumSessions",
  );

  const minimumViewsProps = formToTextFieldProps(
    form,
    "analytics.googleAnalytics4.minimumViews",
  );

  const sourceProps = formToSelectProps(
    form,
    "analytics.googleAnalytics4.source",
  );

  const dateRangeInput = React.useMemo(
    () =>
      withDisplayValue((value) =>
        t("sources.analytics.googleAnalytics.rangeText", {
          lastNDays: value,
        }),
      ),
    [t],
  );

  return (
    <div className={classes.root}>
      <div className={classes.property}>
        {property ? (
          <>
            <Typography
              variant="captionSemiBold"
              data-testid="crawl-settings-ga4-name"
            >
              {property.name}
            </Typography>
            <Typography
              variant="subtitle4"
              data-testid="crawl-settings-ga4-account-name"
            >
              {property.accountName}
            </Typography>
          </>
        ) : (
          <Typography
            variant="captionSemiBold"
            data-testid="crawl-settings-ga4-display-name"
          >
            {t("sources.analytics.googleAnalytics4.noneSelected")}
          </Typography>
        )}
      </div>

      <TextFieldWithOptions
        {...lastNDaysProps}
        disabled={disabled || lastNDaysProps.disabled}
        options={lastDays}
        onOptionSelect={(value) =>
          form.setFieldValue(
            "analytics.googleAnalytics4.lastNDays",
            value,
            true,
          )
        }
        renderOption={(range) =>
          t("sources.analytics.googleAnalytics.rangeText", {
            lastNDays: range,
          })
        }
        label={t("sources.analytics.googleAnalytics4.dateRange")}
        placeholder={t(
          "sources.analytics.googleAnalytics4.dateRangePlaceholder",
        )}
        InputProps={{
          inputComponent: dateRangeInput,
        }}
        className={classes.input}
      />

      <TextField
        {...minimumSessionsProps}
        disabled={disabled || minimumSessionsProps.disabled}
        label={t("sources.analytics.googleAnalytics4.minimumSessions")}
        placeholder={t(
          "sources.analytics.googleAnalytics4.minimumSessionsPlaceholder",
        )}
        className={classes.input}
      />

      <TextField
        {...minimumViewsProps}
        disabled={disabled || minimumViewsProps.disabled}
        label={t("sources.analytics.googleAnalytics4.minimumViews")}
        placeholder={t(
          "sources.analytics.googleAnalytics4.minimumViewsPlaceholder",
        )}
        className={classes.input}
      />

      <Select
        {...sourceProps}
        disabled={disabled || sourceProps.disabled}
        label={t("sources.analytics.googleAnalytics4.filter")}
        className={classes.input}
      >
        {sourceOptions.map((source) => (
          <MenuItem key={source.value} value={source.value}>
            {source.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export function TextFieldWithOptions({
  options,
  renderOption,
  onOptionSelect,
  ...props
}: StandardTextFieldProps & {
  options?: string[];
  renderOption?: (value: string) => React.ReactNode;
  onOptionSelect?: (value: string) => void;
}): JSX.Element {
  return (
    <Autocomplete
      id={props.id}
      value={props.value as string}
      onInputChange={(_, value) => onOptionSelect?.(value)}
      disabled={props.disabled}
      options={options ?? []}
      filterOptions={(items) => items}
      renderOption={renderOption}
      freeSolo
      disableClearable
      renderInput={(params) => (
        <TextField {...params} inputRef={params.InputProps.ref} {...props} />
      )}
    />
  );
}
