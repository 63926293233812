import { Tabs, Tab, Box } from "@material-ui/core";
import { CrawlProgress } from "./crawl-progress/CrawlProgress";
import { CrawlsListTable } from "./crawls-list-table/CrawlsListTable";
import { ChangeEvent } from "react";
import { Options } from "highcharts";
import { RoleCode, RunningCrawlQuery } from "../graphql";
import { useHistory, useParams } from "react-router-dom";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { PauseReasons } from "./crawl-progress/crawl-controls/pause-reasons/PauseReasons";
import { useTranslation, useSession } from "@lumar/shared";
import { useAutoUnArchive } from "./useAutoUnarchive";
import { CrawlSettings } from "./crawl-settings/CrawlSettings";
import { RunningCrawl } from "./useProjectPoller";
import { Routes } from "../_common/routing/routes";

export function ProjectCrawlsTabs({
  project,
  runningCrawl,
  speedChartOptions,
  levelsChartOptions,
}: {
  project?: RunningCrawlQuery;
  runningCrawl?: RunningCrawl;
  speedChartOptions: Options;
  levelsChartOptions: Options;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId?: string;
  }>();
  const params = useURLSearchParams();
  const tabParam = params.get("tab") ?? "edit";
  const history = useHistory();
  const { hasSufficientRole } = useSession();
  const hasEditorRole = hasSufficientRole(RoleCode.Editor);
  useAutoUnArchive();

  const onChange = (
    _: ChangeEvent<unknown>,
    value: "edit" | "progress" | "history",
  ): void => {
    if (!projectId) return;
    history.push(Routes.Crawls.getUrl({ accountId, projectId, tab: value }));
  };

  function showTab(tabParam: string): JSX.Element {
    switch (tabParam) {
      case "edit":
        return <CrawlSettings />;
      case "progress":
        return (
          <CrawlProgress
            project={project}
            runningCrawl={runningCrawl}
            speedChartOptions={speedChartOptions}
            levelsChartOptions={levelsChartOptions}
          />
        );
      case "history":
        return <CrawlsListTable />;
    }

    return <></>;
  }

  return (
    <>
      <Tabs
        value={tabParam}
        variant="standard"
        indicatorColor="primary"
        onChange={onChange}
      >
        {hasEditorRole ? (
          <Tab
            label={<span>{t("tabs.settings")}</span>}
            value={"edit"}
            data-testid="crawl-settings-tab"
          />
        ) : null}
        <Tab
          disabled={!projectId}
          label={<span>{t("tabs.crawlProgress")}</span>}
          value={"progress"}
          data-testid="crawl-progress-tab"
        />
        <Tab
          disabled={!projectId}
          label={t("tabs.history")}
          value={"history"}
          data-testid="crawl-history-tab"
        />
      </Tabs>
      <PauseReasons runningCrawl={runningCrawl} />
      <Box marginTop="18px">{showTab(tabParam)}</Box>
    </>
  );
}
