import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, makeStyles } from "@material-ui/core";
import { ModuleCode } from "../../graphql";
import { SeoModuleIcon } from "./icons/SeoModuleIcon";
import { AccessibilityModuleIcon } from "./icons/AccessibilityModuleIcon";
import { SiteSpeedModuleIcon } from "./icons/SiteSpeedModuleIcon";
import { Typography } from "../typography/Typography";
import { Button } from "../buttons/Button";
import { ArrowRightSolid, CheckSolid } from "../../icons";
import clsx from "clsx";
const availableModules = {
    [ModuleCode.Seo]: SeoModuleIcon,
    [ModuleCode.Accessibility]: AccessibilityModuleIcon,
    [ModuleCode.SiteSpeed]: SiteSpeedModuleIcon,
};
export function ModuleSelector({ modules, onModuleSelected, title, description, disabled = false, selected, classes: outerClasses, }) {
    const classes = useStyles();
    return (_jsxs(Paper, { className: clsx(classes.root, outerClasses === null || outerClasses === void 0 ? void 0 : outerClasses.root), children: [title && (_jsx(Typography, { variant: "h2SemiBold", component: "p", children: title })), description && _jsx(Typography, { variant: "body2", children: description }), _jsx("div", { className: clsx(classes.container, outerClasses === null || outerClasses === void 0 ? void 0 : outerClasses.container), children: modules.map((module) => {
                    var _a;
                    const isSelected = module.code === selected;
                    const Icon = (_a = availableModules[module.code]) !== null && _a !== void 0 ? _a : SeoModuleIcon;
                    const moduleProps = isSelected
                        ? { actionLabel: "Selected", icon: _jsx(CheckSolid, {}) }
                        : { actionLabel: module.actionLabel, icon: _jsx(ArrowRightSolid, {}) };
                    return (_jsxs("div", { className: clsx(classes.module, isSelected ? classes.selected : undefined, outerClasses === null || outerClasses === void 0 ? void 0 : outerClasses.module), children: [_jsx("div", { className: clsx(classes.header, isSelected ? classes.selectedHeader : undefined) }), _jsxs("div", { className: clsx(classes.body, isSelected ? classes.selectedBody : undefined), children: [_jsx(Icon, { className: classes.icon }), _jsx(Typography, { variant: "h1SemiBold", component: "p", className: classes.title, children: module.title }), _jsx(Typography, { variant: "body1", className: classes.description, children: module.description }), _jsx(Typography, { variant: "subtitle3SemiBold", className: classes.detail, children: module.detail }), _jsx(Button, { onClick: () => onModuleSelected(module.code), variant: "contained", color: "primary", size: "large", className: classes.button, disabled: disabled && !isSelected, endIcon: moduleProps.icon, "data-testid": `project-settings-select-module-${module.code.toLowerCase()}`, children: moduleProps.actionLabel })] })] }, module.code));
                }) })] }));
}
const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.grey[200],
        padding: theme.spacing(2.125, 2.75, 2.125, 2.75),
    },
    container: {
        display: "flex",
        gap: theme.spacing(6),
        justifyContent: "center",
        margin: theme.spacing(13, 4, 17.5, 4),
        flexWrap: "wrap",
    },
    module: {
        borderRadius: theme.typography.pxToRem(15),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.grey[300],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 300,
        maxWidth: 388,
        overflow: "hidden",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)",
        flexGrow: 1,
        flexBasis: "0%",
        height: 453,
    },
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
        flexBasis: "0%",
        height: 453 - 56,
    },
    selectedBody: {
        backgroundColor: theme.palette.ultraviolet[100],
    },
    selected: {
        borderWidth: 4,
        borderColor: theme.palette.ultraviolet[400],
    },
    header: {
        height: 56,
        backgroundColor: theme.palette.ultraviolet[100],
        width: "100%",
    },
    selectedHeader: {
        backgroundColor: "transparent",
        height: 56,
    },
    title: {
        lineHeight: theme.typography.pxToRem(28),
        marginTop: theme.spacing(11.5),
    },
    description: {
        textAlign: "center",
        lineHeight: theme.typography.pxToRem(20),
        margin: theme.spacing(1.75, 6.75, 6.5, 6.75),
        height: 80,
    },
    detail: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2.25),
        textAlign: "center",
        marginTop: "auto",
        marginLeft: 16,
        marginRight: 16,
    },
    icon: {
        fontSize: theme.typography.pxToRem(48),
        marginTop: theme.typography.pxToRem(-24),
        marginBottom: theme.typography.pxToRem(-24),
        color: theme.palette.ultraviolet[600],
        fill: theme.palette.ultraviolet[200],
    },
    button: {
        marginTop: "auto",
        marginBottom: theme.spacing(4.375),
        paddingLeft: theme.spacing(3.75),
        paddingRight: theme.spacing(3.75),
    },
}));
