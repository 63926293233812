import { useSession } from "@lumar/shared";
import { Grid, Fade, CircularProgress, makeStyles } from "@material-ui/core";
import { Options } from "highcharts";
import React from "react";
import { RoleCode, RunningCrawlQuery } from "../../graphql";
import { CrawlControlsList } from "./crawl-controls/CrawlControlsList";
import { CrawlSpeedCard } from "./progress-dashboard/crawl-speed-card/CrawlSpeedCard";
import { CrawlSpeedChart } from "./progress-dashboard/crawl-speed-chart/CrawlSpeedChart";
import { CrawlStatusCard } from "./progress-dashboard/crawl-status-card/CrawlStatusCard";
import { CrawledUrlsCard } from "./progress-dashboard/crawled-urls-card/CrawledUrlsCard";
import { UrlsCrawledChart } from "./progress-dashboard/urls-crawled-chart/UrlsCrawledChart";
import { RunningCrawlSettings } from "./running-crawl-settings/RunningCrawlSettings";
import Lottie from "react-lottie-player";
import { isCrawlRunning } from "./_common/running-crawl-query-utils";
import twoDevs from "./animations/two-devs.json";
import { useTrail, a } from "@react-spring/web";
import { RunningCrawl } from "../useProjectPoller";
import { CrawlDurationCard } from "./progress-dashboard/crawl-duration-card/CrawlDurationCard";

const useStyles = makeStyles((theme) => ({
  cards: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateRows: "auto",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto",
      rowGap: 1,
    },
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto",
      rowGap: 1,
    },
  },
  lotti: {
    width: "55%",
    margin: "auto",
    marginTop: -70,
    pointerEvents: "none",
    [theme.breakpoints.down("md")]: {
      marginTop: -50,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: -20,
    },
  },
}));

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? "inherit" : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

export function CrawlProgress({
  project,
  runningCrawl,
  speedChartOptions,
  levelsChartOptions,
}: {
  project?: RunningCrawlQuery;
  runningCrawl?: RunningCrawl;
  speedChartOptions: Options;
  levelsChartOptions: Options;
}): JSX.Element {
  const classes = useStyles();
  const { hasSufficientRole } = useSession();
  const canSeeControls = hasSufficientRole(RoleCode.Editor);
  const hasProject = !!project;
  const isCrawling = isCrawlRunning(runningCrawl);

  return (
    <Grid container alignItems="flex-start">
      {canSeeControls ? (
        <Grid style={{ width: 272 }}>
          <CrawlControlsList project={project} runningCrawl={runningCrawl} />
          {project ? (
            <RunningCrawlSettings
              project={project}
              runningCrawl={runningCrawl}
            />
          ) : null}
        </Grid>
      ) : null}
      <Grid
        item
        container
        style={{
          width: canSeeControls ? "calc(100% - 272px)" : "100%",
          marginLeft: canSeeControls ? 0 : -17,
        }}
      >
        <Grid container className={classes.cards}>
          <Grid item>
            <CrawlStatusCard project={project} runningCrawl={runningCrawl} />
          </Grid>
          <Grid item>
            <CrawledUrlsCard project={project} runningCrawl={runningCrawl} />
          </Grid>
          <Grid item>
            <CrawlSpeedCard runningCrawl={runningCrawl} />
          </Grid>
          <Grid item>
            <CrawlDurationCard project={project} runningCrawl={runningCrawl} />
          </Grid>
        </Grid>
        {hasProject ? (
          <Grid item xs={12}>
            <Trail open={isCrawling}>
              <CrawlSpeedChart
                speedChartOptions={speedChartOptions}
                runningCrawl={runningCrawl}
              />
              <UrlsCrawledChart levelsChartOptions={levelsChartOptions} />
            </Trail>
            <Fade in={!isCrawling} mountOnEnter unmountOnExit timeout={100}>
              <div className={classes.lotti}>
                <Lottie loop animationData={twoDevs} play />
              </div>
            </Fade>
          </Grid>
        ) : (
          <CircularProgress style={{ margin: "auto" }} />
        )}
      </Grid>
    </Grid>
  );
}
