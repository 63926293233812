import { CrawlUrlAggregateDimension, ModuleCode } from "../../graphql";
import {
  DataExplorerAggregationCalculation,
  DataExplorerModuleConfig,
} from "../types";

export const dataExplorerModuleConfigs: DataExplorerModuleConfig[] = [
  {
    moduleCode: ModuleCode.Seo,
    primaryDimensionsCodes: [
      CrawlUrlAggregateDimension.HttpStatusCode,
      CrawlUrlAggregateDimension.Level,
      "segment",
      "path",
      "breadcrumb",
    ],
    excludedDimensionsCodes: [
      CrawlUrlAggregateDimension.Js,
      CrawlUrlAggregateDimension.IsImage,
      CrawlUrlAggregateDimension.Css,
    ],
    defaultTableConfig: {
      dimension: CrawlUrlAggregateDimension.HttpStatusCode,
      columns: [
        {
          metric: {
            code: "url",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Count,
        },
        {
          metric: {
            code: "deeprank",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Avg,
        },
        {
          metric: {
            code: "linksInCount",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Sum,
        },
        {
          metric: {
            code: "backlinkCount",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Sum,
        },
      ],
      orderByColumn: "col-1",
      orderDirection: "desc",
    },
  },
  {
    moduleCode: ModuleCode.Accessibility,
    primaryDimensionsCodes: ["segment", "path", "breadcrumb"],
    exclusiveDimensionsCodes: [
      "segment",
      "path",
      "breadcrumb",
      CrawlUrlAggregateDimension.Path0,
      CrawlUrlAggregateDimension.Path1,
      CrawlUrlAggregateDimension.Path2,
      CrawlUrlAggregateDimension.Path3,
      CrawlUrlAggregateDimension.Path4,
      CrawlUrlAggregateDimension.Path5,
      CrawlUrlAggregateDimension.Path6,
      CrawlUrlAggregateDimension.Path7,
      CrawlUrlAggregateDimension.Path8,
      CrawlUrlAggregateDimension.Path9,
      CrawlUrlAggregateDimension.Breadcrumb01,
      CrawlUrlAggregateDimension.Breadcrumb02,
      CrawlUrlAggregateDimension.Breadcrumb03,
      CrawlUrlAggregateDimension.Breadcrumb04,
      CrawlUrlAggregateDimension.Breadcrumb05,
      CrawlUrlAggregateDimension.Breadcrumb06,
      CrawlUrlAggregateDimension.Breadcrumb07,
      CrawlUrlAggregateDimension.Breadcrumb08,
    ],
    defaultTableConfig: {
      dimension: "path",
      columns: [
        {
          metric: {
            code: "url",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Count,
        },
        {
          metric: {
            code: "accessibilityAIssuesCount",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Sum,
        },
        {
          metric: {
            code: "accessibilityAaIssuesCount",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Sum,
        },
        {
          metric: {
            code: "accessibilityAaaIssuesCount",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Sum,
        },
      ],
      orderByColumn: "col-1",
      orderDirection: "desc",
    },
  },
  {
    moduleCode: ModuleCode.SiteSpeed,
    primaryDimensionsCodes: [
      "path",
      "segment",
      CrawlUrlAggregateDimension.Level,
      "breadcrumb",
    ],
    excludedDimensionsCodes: [
      CrawlUrlAggregateDimension.Js,
      CrawlUrlAggregateDimension.IsImage,
      CrawlUrlAggregateDimension.Css,
    ],
    defaultTableConfig: {
      dimension: "path",
      columns: [
        {
          metric: {
            code: "url",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Count,
        },
        {
          metric: {
            code: "savingsScore",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Avg,
        },
        {
          metric: {
            code: "savingsKib",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Avg,
        },
        {
          metric: {
            code: "savingsSecs",
          },
          aggregationCalculation: DataExplorerAggregationCalculation.Avg,
        },
      ],
      orderByColumn: "col-1",
      orderDirection: "desc",
    },
  },
];
